import {Component} from '@angular/core';
import {ClientViewModel, ModelTypes} from '../../../generated-api/models';
import {ApiCrudService, BaseDynamicFormComponent} from 'defdev-angular-nebular';

@Component({
  selector: 'ngx-tour-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent extends BaseDynamicFormComponent<ClientViewModel> {


  protected getService(): ApiCrudService<ClientViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.CLIENT);
  }

}
