import {Component} from '@angular/core';
import {ApiCrudService, BaseDynamicFormComponent, ITableConfig} from 'defdev-angular-nebular';
import {GuideViewModel} from '../../../generated-api/models/guide-view-model';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-guide-details',
  template: `
    <nb-card>
      <nb-card-header>
        Guide information
      </nb-card-header>
    </nb-card>
    <ng-container *ngIf="formModel">
      <ngx-dynamic-form #ngxForm [formModel]="formModel"></ngx-dynamic-form>
      <ngx-config-based-table-component [tableConfig]="bookingsConfig"
                                        *ngIf="formModel?.object?.id > 0"></ngx-config-based-table-component>
      <nb-card>
        <nb-card-footer>
          <button nbButton status="basic" class="mr-2" (click)="onCancel()">Cancel</button>
          <button nbButton status="success" class="mr-2"
                  (click)="ngxForm.updateModel();save()"
                  [disabled]="ngxForm.form.invalid">Save
          </button>
        </nb-card-footer>
      </nb-card>

    </ng-container>

  `,

})
export class GuideDetailsComponent extends BaseDynamicFormComponent<GuideViewModel> {

  // config to table
  userTimingsConfig: ITableConfig = {
    modelType: ModelTypes.GUIDE_BOOKING,
    title: 'Bookings',
    referencedProperties: [{type: 'integer', field: 'guideId', value: () => this.formModel.object.id}],
    addButtonEnabled: true,
    editButtonEnabled: true,
    deleteButtonEnabled: false,
    showBulkUpdate: false,
  };

  bookingsConfig: ITableConfig = {
    modelType: ModelTypes.GUIDE_BOOKING,
    title: 'Bookings',
    referencedProperties: [{type: 'integer', field: 'guideId', value: () => this.formModel.object.id}],
    addButtonEnabled: false,
    deleteButtonEnabled: false,
    showBulkUpdate: false,
    editModes: ['INLINE', 'PAGE'],
    selectionMode: 'single',
  };

  protected getService(): ApiCrudService<GuideViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.GUIDE);
  }

}
