import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from '../pages.component';
import {AccountSettingsComponent} from './account-settings/account-settings.component';
import {UsersSettingsComponent} from './users/users-settings.component';
import {ClientsComponent} from './clients/clients.component';
import {OfficesComponent} from './offices/offices.component';
import {LanguagesComponent} from './languages/languages.component';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {DefaultTourDocumentsComponent} from './default-tour-documents/default-tour-documents.component';
import {ShipsComponent} from './ships/ships.component';
import {ImageLibraryComponent} from './image-library/image-library.component';
import {PortDetailsComponent} from './port-details/port-details.component';
import {PortsComponent} from './ports/ports.component';
import {RegionsComponent} from './regions/regions.component';
import {ServicesComponent} from './services/services.component';
import {
  AppLoggingComponent,
  ConfigListComponent,
  DefaultTableComponentComponent,
  ModelBasedFormComponent,
} from 'defdev-angular-nebular';
import {VenuesComponent} from './venues/venues.component';
import {PortAgenciesComponent} from './port-agencies/port-agencies.component';
import {PortAgencyDetailsComponent} from './port-agency-details/port-agency-details.component';
import {ShipDetailsComponent} from './ship-details/ship-details.component';
import {UserDetailsComponent} from './user-details/user-details.component';
import {VenueDetailsComponent} from './venue-details/venue-details.component';
import {TransportComponent} from './transport/transport.component';
import {TransportDetailsComponent} from './transport-details/transport-details.component';
import {DETAIL_COMPONENT_ROUTE, LIST_COMPONENT_ROUTE} from '../../constants';
import {GuidesComponent} from './users/guides.component';
import {PortCoordinatorsComponent} from './users/port-coordinators.component';
import {GuideDetailsComponent} from './guide-details/guide-details.component';
import {PaymentMethodsComponent} from './payment-methods/payment-methods.component';
import {TransportServicesComponent} from './transport-services/transport-services.component';
import {VenueCategoriesComponent} from './venue-categories/venue-categories.component';
import {VenueGroupsComponent} from './venue-groups/venue-groups.component';
import {VenueDescriptionsComponent} from './venue-descriptions/venue-descriptions.component';
import {TabPagesComponent} from "../tab-pages.component";
import {ReportsComponent} from "./reports/reports.component";

const routes: Routes = [{
  path: 'settings',

  children: [
    // default components if route not exists in modelmapper
    {
      path: 'list/:model_type',
      component: DefaultTableComponentComponent,
    },
    {
      path: 'detail/:model_type/:id',
      component: ModelBasedFormComponent,
    },
    {
      path: 'account',
      component: AccountSettingsComponent,
    },
    {
      path: 'user/' + LIST_COMPONENT_ROUTE,
      component: UsersSettingsComponent,
    },
    {
      path: 'guide/' + LIST_COMPONENT_ROUTE,
      component: GuidesComponent,
    },
    {
      path: 'guide/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: GuideDetailsComponent,
    },
    {
      path: 'user/port-coordinators',
      component: PortCoordinatorsComponent,
    },
    {
      path: 'user/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: UserDetailsComponent,
    },
    {
      path: 'client/' + LIST_COMPONENT_ROUTE,
      component: ClientsComponent,
    },
    {
      path: 'client/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: ClientDetailsComponent,
    },
    {
      path: 'payment-method/' + LIST_COMPONENT_ROUTE,
      component: PaymentMethodsComponent,
    },
    {
      path: 'transport-service/' + LIST_COMPONENT_ROUTE,
      component: TransportServicesComponent,
    },
    {
      path: 'venue-category/' + LIST_COMPONENT_ROUTE,
      component: VenueCategoriesComponent,
    },
    {
      path: 'venue-group/' + LIST_COMPONENT_ROUTE,
      component: VenueGroupsComponent,
    },
    {
      path: 'venue-description/' + LIST_COMPONENT_ROUTE,
      component: VenueDescriptionsComponent,
    },
    {
      path: 'office/' + LIST_COMPONENT_ROUTE,
      component: OfficesComponent,
    },
    {
      path: 'language/' + LIST_COMPONENT_ROUTE,
      component: LanguagesComponent,
    },
    {
      path: 'port/' + LIST_COMPONENT_ROUTE,
      component: PortsComponent,
    },
    {
      path: 'port/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: PortDetailsComponent,
    },
    {
      path: 'port-agency/' + LIST_COMPONENT_ROUTE,
      component: PortAgenciesComponent,
    },
    {
      path: 'port-agency/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: PortAgencyDetailsComponent,
    },


    {
      path: 'region/' + LIST_COMPONENT_ROUTE,
      component: RegionsComponent,
    },
    {
      path: 'service/' + LIST_COMPONENT_ROUTE,
      component: ServicesComponent,
    },
    {
      path: 'ship/' + LIST_COMPONENT_ROUTE,
      component: ShipsComponent,
    },
    {
      path: 'ship/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: ShipDetailsComponent,
    },
    {
      path: 'venue/' + LIST_COMPONENT_ROUTE,
      component: VenuesComponent,
    },
    {
      path: 'venue/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: VenueDetailsComponent,
    },

    {
      path: 'default-documents',
      component: DefaultTourDocumentsComponent,
    },
    {
      path: 'image-library',
      component: ImageLibraryComponent,
    },
    {
      path: 'logs',
      component: AppLoggingComponent,
    },

    {
      path: 'config',
      component: ConfigListComponent,
    },
    {
      path: 'transport/' + LIST_COMPONENT_ROUTE,
      component: TransportComponent,
    },
    {
      path: 'report/' + LIST_COMPONENT_ROUTE,
      component: ReportsComponent,
    },
    {
      path: 'transport/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: TransportDetailsComponent,
    },
    {
      path: '',
      redirectTo: 'account',
      pathMatch: 'full',
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxSettingsRoutingModule {
}
