import {Component, OnInit} from '@angular/core';
import {ApiConfigService} from '../../../generated-api/services';
import {ConfigViewModel} from '../../../generated-api/models';
import {extractContent} from 'defdev-angular-nebular';


@Component({
  selector: 'ngx-default-tour-documents',
  templateUrl: './default-tour-documents.component.html',
  styleUrls: ['./default-tour-documents.component.scss'],
})
export class DefaultTourDocumentsComponent implements OnInit {
  config: ConfigViewModel;

  constructor(private configService: ApiConfigService) {
  }

  ngOnInit() {
    this.configService.GetByAppKey('DEFAULT_TOUR_DOCUMENTS').pipe(extractContent()).subscribe(config => {
      this.config = config;
    });
  }

}
