import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-tour-languages',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class LanguagesComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.LANGUAGE,
    title: 'Languages',
  };

  constructor() {
  }


}
