import {Component, OnInit} from '@angular/core';
import {ConfigViewModel} from '../../../generated-api/models/config-view-model';
import {ApiConfigService} from '../../../generated-api/services/api-config.service';
import {extractContent} from 'defdev-angular-nebular';

@Component({
  selector: 'ngx-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss'],
})
export class ImageLibraryComponent implements OnInit {
  config: ConfigViewModel;

  constructor(private configService: ApiConfigService) {
  }

  ngOnInit() {
    this.configService.GetByAppKey('IMAGE_LIBRARY').pipe(extractContent()).subscribe(config => {
      this.config = config;
    });
  }


}
