/* tslint:disable */
type ClientStatus =
  'Not_Active' |
  'Active' |
  'Bid' |
  'Fired';
module ClientStatus {
  export const NOT_ACTIVE: ClientStatus = 'Not_Active';
  export const ACTIVE: ClientStatus = 'Active';
  export const BID: ClientStatus = 'Bid';
  export const FIRED: ClientStatus = 'Fired';
  export function values(): ClientStatus[] {
    return [
      NOT_ACTIVE,
      ACTIVE,
      BID,
      FIRED
    ];
  }
}

export { ClientStatus }