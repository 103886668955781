import { Subject } from 'rxjs';
import {  Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  eventsSubject: Subject<string> = new Subject<string>();

  TestingFunction() { }
}
