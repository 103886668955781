/* tslint:disable */
type GuideStatus =
  'Preferred' |
  'Active' |
  'NonActive' |
  'Retired' |
  'DNU' |
  'Deceased';
module GuideStatus {
  export const PREFERRED: GuideStatus = 'Preferred';
  export const ACTIVE: GuideStatus = 'Active';
  export const NON_ACTIVE: GuideStatus = 'NonActive';
  export const RETIRED: GuideStatus = 'Retired';
  export const DNU: GuideStatus = 'DNU';
  export const DECEASED: GuideStatus = 'Deceased';
  export function values(): GuideStatus[] {
    return [
      PREFERRED,
      ACTIVE,
      NON_ACTIVE,
      RETIRED,
      DNU,
      DECEASED
    ];
  }
}

export { GuideStatus }