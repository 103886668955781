/* tslint:disable */
type OfficeSubscriptionTypeEnum =
  'ALL' |
  'TOURS' |
  'BIDS';
module OfficeSubscriptionTypeEnum {
  export const ALL: OfficeSubscriptionTypeEnum = 'ALL';
  export const TOURS: OfficeSubscriptionTypeEnum = 'TOURS';
  export const BIDS: OfficeSubscriptionTypeEnum = 'BIDS';
  export function values(): OfficeSubscriptionTypeEnum[] {
    return [
      ALL,
      TOURS,
      BIDS
    ];
  }
}

export { OfficeSubscriptionTypeEnum }