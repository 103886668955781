import {Component, OnInit} from '@angular/core';
import {ApiAuthenticationService, ApiUsersService} from '../../../generated-api/services';
import {NbAuthJWTToken, NbTokenService} from '@nebular/auth';
import {environment} from '../../../../environments/environment';
import {NbRoleProvider} from '@nebular/security';
import {map} from 'rxjs/operators';
import {containsAny, DialogService, extractContent, ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';
import { isEditable } from 'defdev-angular-nebular/lib/components/smart-table-utilities/smart-table/smart-table-utils';

@Component({
  selector: 'ngx-base-users-settings',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig" ' +
    '(custom)="onCustomAction($event)"></ngx-config-based-table-component>',
})
export class BaseUsersComponent implements OnInit {

  protected usersConfig: ITableConfig;


  constructor(
    readonly service: ApiUsersService,
    readonly authService: ApiAuthenticationService,
    readonly tokenService: NbTokenService,
    readonly roleProvider: NbRoleProvider,
    readonly dialogService: DialogService) {
  }

  protected onCustomAction(event) {
    if (event.action === 'password') {
      this.dialogService.showPasswordDialog({}).onConfirm((context) => {
        this.service.ChangePassword({password: context.input, userId: event.data.id}).subscribe(() => {
        });
      });
    } else if (event.action === 'login-as') {
      this.authService.LoginAsOtherUser(event.data.id)
        .pipe(extractContent())
        .subscribe(newToken => {
          this.tokenService.set(new NbAuthJWTToken(newToken, environment.securityStrategyName));
          window.location.reload();
        });
    }

  }

  // , super admin, finaliser, planner, edit only
  ngOnInit(): void {
    this.initTable();
  }

  protected getTableConfig(isEdit: boolean,
    additionalUserActions: { name: string, title: string }[]): ITableConfig {
    return {
      title: 'Users',
      modelType: ModelTypes.USER,
      // actions for elements in row
      customTableActions: additionalUserActions,
      showBulkUpdate: false,
      // if ca be edited inline
      editModes: ['PAGE', 'INLINE'],
      showActionsToolbar: isEdit,
      editButtonEnabled: isEdit,
      addButtonEnabled: isEdit,
      deleteButtonEnabled: isEdit
    };
  }

  protected initTable() {
    const userActions = [];
    var isEdit: boolean = false;

    this.roleProvider.getRole()
      .pipe(
        map((role: string | string[]) => Array.isArray(role) ? role : [role]))
      .subscribe(roles => {
        var editmode = [];
        if (this.hasLoginAs(roles)) {
          userActions.push({name: 'login-as', title: '<i class="fas fa-user" title="Login as"></i>'});
        }
        if (this.hasChangePassword(roles)) {
          userActions.push({name: 'password', title: '<i class="fas fa-lock" title="Change password"></i>'});
        }
        if (this.hasEdit(roles)) {
          isEdit = true;
        }

        this.usersConfig = this.getTableConfig(isEdit, userActions);
      });
  }

  protected hasLoginAs(roles: string[]) {
    return containsAny(roles, ['SuperAdmin']);
  }

  protected hasChangePassword(roles: string[]) {
    return containsAny(roles, ['SuperAdmin']);
  }

  protected hasEdit(roles: string[]) {
    return containsAny(roles, ['SuperAdmin']);
  }
}
