import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-ops-document-list',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
  styles: [],
})
export class OpsDocumentListComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.OPS_DOCUMENT,
    title: 'Ops documents',
    editModes: ['PAGE'],
    defaultFiltersFunction: () => [{
      type: 'boolean',
      operator: '=',
      field: 'invoiced',
      value: 'false',
    },
      {
        type: 'boolean',
        operator: '=',
        field: 'cancelled',
        value: 'false',
      },
    ],
  };

  constructor() {
  }

}
