import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-venue-descriptions',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class VenueDescriptionsComponent {
  tableConfig: ITableConfig = {
    modelType: ModelTypes.VENUE_DESCRIPTION,
    editModes: ['MODAL'],
    title: 'Venue descriptions',
  };


  constructor() {
  }


}
