
export const constants = {
  appName: 'Guides',
  cacheKey: 'app_cache',
  cacheMaxAge: 30000,
  defaultID: 0,
  securityStrategyName: 'app-security-strategy',
  dateFormat: 'dd/MM/yyyy',
  timeFormat: 'HH:mm',
  dateTimeFormat: 'dd/MM/yyyy HH:mm',
  apiDateTimeFormat: 'yyyy-MM-ddTHH:mm:ss',
  doubleFormat: '1.1-3',
  integerFormat: '1.0-0',
  googleMapsApiKey: 'AIzaSyDqMUiEVK8oBXwG1TWCB4hcRfXdKfwMgig',
  defaultLocale: 'en-US',
  defaultPage: '/pages/operation/calendar',
};


export const DETAIL_COMPONENT_ROUTE = 'detail';
export const LIST_COMPONENT_ROUTE = 'list';

// dynamic compnents routes based on model type
export const DEFAULT_LIST_COMPONENT_ROUTE = 'pages/settings/' + LIST_COMPONENT_ROUTE;
export const DEFAULT_DETAILS_COMPONENT_ROUTE = 'pages/settings/' + DETAIL_COMPONENT_ROUTE;
