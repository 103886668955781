import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-port-agencies',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class PortAgenciesComponent {
  tableConfig: ITableConfig = {
    modelType: ModelTypes.PORT_AGENCY,
    title: 'Port agencies',
    editModes: ['PAGE'],
  };

  constructor() {
  }

}
