import LatLngBounds = google.maps.LatLngBounds;
import LatLng = google.maps.LatLng;


export function createLatLngBounds(lat: number, lng: number, offset = 0): LatLngBounds {
  return new google.maps.LatLngBounds(
    {lat: lat - offset, lng: lng - offset},
    {lat: lat + offset, lng: lng + offset}
  );
}

export function createLatLng(lat: number, lng: number): LatLng {
  return new google.maps.LatLng(lat, lng);
}

export function createBounds(latLngs: LatLng[]): LatLngBounds {
  const bounds = new google.maps.LatLngBounds();
  latLngs.forEach(latLng => bounds.extend(latLng));
  return bounds;
}
