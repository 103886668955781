import { Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { ConfigBasedTableComponent, containsAny, DialogService, extractContent, ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';
import { BaseUsersComponent } from './base-users.component';
import { map } from 'rxjs/operators';
import { ApiAuthenticationService, ApiUsersService } from '../../../generated-api/services';
import { NbRoleProvider } from '@nebular/security';

@Component({
  selector: 'ngx-users-settings',
  template: `
    <ngx-config-based-table-component #guidestable
                                      [tableConfig]="this.tableConfig"
                                      (custom)="onCustomAction($event)"></ngx-config-based-table-component>`,
})
export class GuidesComponent implements OnInit {

  constructor(
    readonly service: ApiUsersService,
    readonly authService: ApiAuthenticationService,
    readonly dialogService: DialogService,
    readonly roleProvider: NbRoleProvider) {
  }

  additionalUserActions: any[] = [];
  isEdit: boolean = false;
  canDelete: boolean = false;
  tableConfig: ITableConfig = {
    title: 'Guides',
    modelType: ModelTypes.GUIDE,
    // actions for elements in row
    customTableActions: this.additionalUserActions,
    showBulkUpdate: false,
    // if ca be edited inline
    editModes: ['PAGE', 'INLINE'],
    referencedProperties: [
      {
        type: 'string',
        field: 'RolesString',
        value: 'Guide',
        operator: 'like',
      },
    ],
    visibleColumns: ['fullName', 'email', 'phone', 'guideStatus'],
    filterableColumns: ['firstName', 'lastName', 'portsId', 'officesId', 'languagesId'],
    exportableColumns: ['fullName', 'email', 'phone', 'languagesId', 'guideQualification',
      'guideAssociation', 'portsId', 'officesId', 'town', 'guideStatus'],
    showActionsToolbar: this.isEdit,
    editButtonEnabled: this.isEdit,
    addButtonEnabled: this.isEdit,
    deleteButtonEnabled: this.canDelete
  };

  @ViewChild('guidestable', { static: false })
  guidestable: ConfigBasedTableComponent;

  protected getTableConfig(isEdit: boolean, canDelete: boolean, additionalUserActions: { name: string; title: string }[]): ITableConfig {
    console.log("editing: " + isEdit);
    return {
      title: 'Guides',
      modelType: ModelTypes.GUIDE,
      // actions for elements in row
      customTableActions: additionalUserActions,
      showBulkUpdate: false,
      // if ca be edited inline
      editModes: ['PAGE'],
      referencedProperties: [
        {
          type: 'string',
          field: 'RolesString',
          value: 'Guide',
          operator: 'like',
        },
      ],
      visibleColumns: ['fullName', 'email', 'phone', 'guideStatus'],
      filterableColumns: ['firstName', 'lastName', 'portsId', 'officesId', 'languagesId'],
      exportableColumns: ['fullName', 'email', 'phone', 'languagesId', 'guideQualification',
        'guideAssociation', 'portsId', 'officesId', 'town', 'guideStatus'],
      showActionsToolbar: isEdit,
      editButtonEnabled: isEdit,
      addButtonEnabled: isEdit,
      deleteButtonEnabled: canDelete
    };
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initTable();
  }

  protected onCustomAction(event) {
    if (event.action === 'password') {
      this.dialogService.showPasswordDialog({}).onConfirm((context) => {
        this.service.ChangePassword({ password: context.input, userId: event.data.id }).subscribe(() => {
        });
      });
    }
  }

  protected initTable() {

    console.log(this.guidestable);

    this.roleProvider.getRole()
      .pipe(
        map((role: string | string[]) => Array.isArray(role) ? role : [role]))
      .subscribe(roles => {
        if (this.hasChangePassword(roles)) {
          this.additionalUserActions.push({ name: 'password', title: '<i class="fas fa-lock" title="Change password"></i>' });
        }
        if (this.hasEdit(roles)) {
          this.isEdit = true;
        }
        if (this.hasDelete(roles)) this.canDelete = true;
        this.tableConfig = this.getTableConfig(this.isEdit, this.canDelete, this.additionalUserActions);
      });
  }

  protected hasChangePassword(roles: string[]) {
    return true;
  }
  protected hasEdit(roles: string[]) {
    return containsAny(roles, ['Admin']);
  }
  protected hasDelete(roles: string[]) {
    return containsAny(roles, ['SuperAdmin']);
  }
}
