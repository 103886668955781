import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-regions',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class RegionsComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.REGION,
    editModes: ['PAGE'],
    title: 'Regions',
  };

  constructor() {
  }

}
