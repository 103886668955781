import {Component} from '@angular/core';
import {PortViewModel} from '../../../generated-api/models/port-view-model';
import {ApiCrudService, BaseDynamicFormComponent} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-port-details',
  templateUrl: './port-details.component.html',
  styleUrls: ['./port-details.component.scss'],
})
export class PortDetailsComponent extends BaseDynamicFormComponent<PortViewModel> {


  protected getService(): ApiCrudService<PortViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.PORT);
  }


}
