import {Component} from '@angular/core';
import {BaseUsersComponent} from './base-users.component';
import {ITableConfig} from "defdev-angular-nebular";
import {ModelTypes} from "../../../generated-api/models/model-types";

@Component({
  selector: 'ngx-users-settings',
  template: `
    <ngx-config-based-table-component *ngIf="usersConfig"
                                      [tableConfig]="usersConfig"
                                      (custom)="onCustomAction($event)"></ngx-config-based-table-component>`,
})
export class UsersSettingsComponent extends BaseUsersComponent {

  protected getTableConfig(isEdit: boolean, additionalUserActions: { name: string; title: string }[]): ITableConfig {
    return {
      title: 'Users',
      modelType: ModelTypes.USER,
      // actions for elements in row
      customTableActions: additionalUserActions,
      showBulkUpdate: false,
      // if ca be edited inline
      editModes: ['PAGE', 'INLINE'],
      defaultFiltersFunction: () => [
        {
          type: 'string',
          condition: "or",
          rules: [
            {
              type: 'string',
              operator: 'like',
              field: 'RolesString',
              value: 'Admin',
            },
            {
              type: 'string',
              operator: 'like',
              field: 'RolesString',
              value: 'Viewer',
            },
            {
              type: 'string',
              operator: 'like',
              field: 'RolesString',
              value: 'Planner',
            },
            {
              type: 'string',
              operator: 'like',
              field: 'RolesString',
              value: 'Finalizer',
            }

          ]
        },
      ],
      showActionsToolbar: isEdit,
      editButtonEnabled: isEdit,
      addButtonEnabled: isEdit,
      deleteButtonEnabled: isEdit
    };
  }
}
