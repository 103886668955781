import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {
  ApiBidsService,
  ApiBookingService,
  ApiClientsService,
  ApiConfigService,
  ApiDocumentsService,
  ApiGuideBookingService,
  ApiGuidesService,
  ApiLanguagesService,
  ApiNotesService,
  ApiOfficesService,
  ApiOpsDocumentActionService,
  ApiOpsDocumentService,
  ApiPaymentMethodsService,
  ApiPortAgencyService,
  ApiPortAgentService,
  ApiPortsService,
  ApiRegionsService,
  ApiServicesService,
  ApiShipItineryService,
  ApiShipsService,
  ApiToursService,
  ApiTourTimingItineryService,
  ApiTourTimingsService,
  ApiTourUserLocationService,
  ApiTransportBookingService,
  ApiTransportContactService,
  ApiTransportService, ApiTransportServicesService,
  ApiUsersService,
  ApiVenueBookingService,
  ApiVenueCategorysService,
  ApiVenueContactService,
  ApiVenueDescriptionsService,
  ApiVenueGroupsService,
  ApiVenueService,
} from './generated-api/services';

import {
  ApiCrudService,
  DialogService,
  extractContent,
  getDefaultId,
  getFromDictIgnoreCase,
  ModelMapperService,
} from 'defdev-angular-nebular';
import {Router} from '@angular/router';
import {
  DEFAULT_DETAILS_COMPONENT_ROUTE,
  DEFAULT_LIST_COMPONENT_ROUTE,
  DETAIL_COMPONENT_ROUTE,
  LIST_COMPONENT_ROUTE,
} from './constants';
import {EditDialogData} from 'defdev-angular-nebular/lib/components/dialog/dynamic-edit-dialog/dynamic-edit-dialog.component';
import {DialogConfigModel} from 'defdev-angular-nebular/lib/components/dialog/dialog-models';


@Injectable({
  providedIn: 'root',
})
export class AppModelMapperService implements ModelMapperService {

  servicesMapping: { [id: string]: ApiCrudService<any>; };
  // define all mappings for custom detail/list view
  modelPrefixes: { [id: string]: string } = {
    'BID': 'pages/operation/',
    'OPS_DOCUMENT': 'pages/operation/ops-document/',
    'TOUR': 'pages/tour/',
    'TOUR_TIMING': 'pages/tour/tour-timing/',
    'CLIENT': 'pages/settings/client/',
    'PORT_AGENCY': 'pages/settings/port-agency/',
    'PORT': 'pages/settings/port/',
    'SHIP': 'pages/settings/ship/',
    'TRANSPORT': 'pages/settings/transport/',
    'GUIDE': 'pages/settings/guide/',
    'VENUE': 'pages/settings/venue/',
    'DETAILED_BOOKING_INFO': 'pages/tour/tour-timing/',
  };

  constructor(
    private usersService: ApiUsersService,
    private guidesService: ApiGuidesService,
    private tourService: ApiToursService,
    private clientService: ApiClientsService,
    private languageService: ApiLanguagesService,
    private officeService: ApiOfficesService,
    private tourTimingService: ApiTourTimingsService,
    private tourLocationService: ApiTourUserLocationService,
    private portService: ApiPortsService,
    private documentService: ApiDocumentsService,
    private configService: ApiConfigService,
    private noteService: ApiNotesService,
    private bidService: ApiBidsService,
    private serviceServices: ApiServicesService,
    private regionService: ApiRegionsService,
    private shipServices: ApiShipsService,
    private shipItienaryService: ApiShipItineryService,
    private venueService: ApiVenueService,
    private transportService: ApiTransportService,
    private transportContactService: ApiTransportContactService,
    private transportBookingService: ApiTransportBookingService,
    private guideBookingService: ApiGuideBookingService,
    private opsDocumentService: ApiOpsDocumentService,
    private opsDocumentActionService: ApiOpsDocumentActionService,
    private tourTimingItineryService: ApiTourTimingItineryService,
    private portAgentService: ApiPortAgentService,
    private portAgencyService: ApiPortAgencyService,
    private bookingService: ApiBookingService,
    private paymentMethodService: ApiPaymentMethodsService,
    private transportServiceService: ApiTransportServicesService,
    private venueCategoryService: ApiVenueCategorysService,
    private venueDescriptionService: ApiVenueDescriptionsService,
    private venueGroupService: ApiVenueGroupsService,
    private venueBookingService: ApiVenueBookingService,
    private venueContactService: ApiVenueContactService,
    private dialogService: DialogService,
    private router: Router,
  ) {

    this.servicesMapping = {
      'VENUE_GROUP': this.venueGroupService as ApiCrudService<any>,
      'VENUE_DESCRIPTION': this.venueDescriptionService as ApiCrudService<any>,
      'VENUE_CATEGORY': this.venueCategoryService as ApiCrudService<any>,
      'TRANSPORT_SERVICE': this.transportServiceService as ApiCrudService<any>,
      'LANGUAGE': this.languageService as ApiCrudService<any>,
      'USER': this.usersService as ApiCrudService<any>,
      'GUIDE': this.guidesService as ApiCrudService<any>,
      'TOUR': this.tourService as ApiCrudService<any>,
      'CLIENT': this.clientService as ApiCrudService<any>,
      'OFFICE': this.officeService as ApiCrudService<any>,
      'TOUR_TIMING': this.tourTimingService as ApiCrudService<any>,
      'TOUR_LOCATION': this.tourLocationService as ApiCrudService<any>,
      'PORT': this.portService as ApiCrudService<any>,
      'DOCUMENT': this.documentService as ApiCrudService<any>,
      'CONFIG': this.configService as ApiCrudService<any>,
      'NOTE': this.noteService as ApiCrudService<any>,
      'BID': this.bidService as ApiCrudService<any>,
      'SERVICE': this.serviceServices as ApiCrudService<any>,
      'REGION': this.regionService as ApiCrudService<any>,
      'SHIP': this.shipServices as ApiCrudService<any>,

      'VENUE': this.venueService as ApiCrudService<any>,
      'OPS_DOCUMENT': this.opsDocumentService as ApiCrudService<any>,
      'OPS_DOCUMENT_ACTION': this.opsDocumentActionService as ApiCrudService<any>,
      'TOUR_TIMING_ITINERY': this.tourTimingItineryService as ApiCrudService<any>,
      'PORT_AGENT': this.portAgentService as ApiCrudService<any>,
      'PORT_AGENCY': this.portAgencyService as ApiCrudService<any>,
      'SHIP_ITINERY': this.shipItienaryService as ApiCrudService<any>,
      'TRANSPORT': this.transportService as ApiCrudService<any>,
      'TRANSPORT_CONTACT': this.transportContactService as ApiCrudService<any>,
      'TRANSPORT_BOOKING': this.transportBookingService as ApiCrudService<any>,
      'GUIDE_BOOKING': this.guideBookingService as ApiCrudService<any>,
      'BOOKING': this.bookingService as ApiCrudService<any>,
      'PAYMENT_METHOD': this.paymentMethodService as ApiCrudService<any>,
      'VENUE_BOOKING': this.venueBookingService as ApiCrudService<any>,
      'VENUE_CONTACT': this.venueContactService as ApiCrudService<any>,
    };
  }

  getCrudServiceForModel(modelType: string): ApiCrudService<any> {
    return getFromDictIgnoreCase(modelType, this.servicesMapping);

  }

  getAllCrudServices(): { [key: string]: ApiCrudService<any> } {
    return this.servicesMapping;
  }

  async navigateToList(modelType: string) {
    const prefixForModel = getFromDictIgnoreCase(modelType, this.modelPrefixes);
    if (!prefixForModel) {
      await this.router.navigate([DEFAULT_LIST_COMPONENT_ROUTE + modelType], {queryParams: {}});
    } else {
      await this.router.navigate([prefixForModel + LIST_COMPONENT_ROUTE]);
    }
  }

  async navigateToDetails(modelType: string, id: any, queryParams: { [key: string]: any }) {
    const prefixForModel = getFromDictIgnoreCase(modelType, this.modelPrefixes);


    if (!prefixForModel) {
      const modelTypeForUrl = modelType.toLowerCase().replace(' ', '-');
      await this.router.navigate([DEFAULT_DETAILS_COMPONENT_ROUTE + '/' + modelTypeForUrl + '/' + getDefaultId(id)],
        {queryParams: queryParams});
    } else {
      await this.router.navigate([prefixForModel + DETAIL_COMPONENT_ROUTE + '/' + getDefaultId(id)],
        {queryParams: Object.assign(queryParams, {'id': getDefaultId(id)})});
    }
  }

  showEditDialog(modelName: string, additionalParams: { [key: string]: string } = {},
                 dialogConfig: DialogConfigModel = null): Observable<any> {
    const crudService = this.getCrudServiceForModel(modelName);
    if (modelName === 'user' && crudService != null) {
      if (additionalParams['RolesString'] === undefined) {
        additionalParams['RolesString'] = 'PortCoordinator';
      }
    }
    const editData = crudService.GetWithEditData({
      additionalParams: additionalParams,
    }).pipe(
      extractContent(),
      flatMap(form => {
        const config: EditDialogData<any> = {formModel: form};
        if (dialogConfig) {
          Object.assign(config, dialogConfig);
        }
        return this.dialogService.showDynamicEditorDialog(config).confirm;
      }),
      flatMap(result => crudService.AddOrUpdate(result.formModel.object)),
      extractContent(),
    );
    return editData;
  }
}
