import {Component, OnInit} from '@angular/core';
import {ApiToursService, ApiTourTimingsService, ApiTourUserLocationService} from '../../../generated-api/services';
import {TourMapViewModel} from '../../../generated-api/models/tour-map-view-model';
import {TourTimingViewModel} from '../../../generated-api/models/tour-timing-view-model';
import {TourUserLocationViewModel} from '../../../generated-api/models/tour-user-location-view-model';
import {createBounds, createLatLng} from '../../../utils/map-utils';
import {IconProvider} from '../../../utils/icon-provider';
import {extractContent} from 'defdev-angular-nebular';
import LatLngBounds = google.maps.LatLngBounds;

@Component({
  selector: 'ngx-tour-list-map',
  templateUrl: './tour-list-map.component.html',
  styleUrls: ['./tour-list-map.component.scss'],
})
export class TourListMapComponent implements OnInit {

  mapProp = {
    lat: 9.234960,
    lon: 20.357078,
    zoom: 3,
    mapTypeId: 'hybrid',
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: true,
    scaleControl: true,
  };
  fitBounds: LatLngBounds;


  selectedTour: TourMapViewModel = null;
  selectedTourTiming: TourTimingViewModel = null;
  tours: Array<TourMapViewModel> = null;
  filteredTours: Array<TourMapViewModel> = null;
  tourTimings: Array<TourTimingViewModel> = null;
  usersLocations: Array<TourUserLocationViewModel> = null;
  tourDate: Date;

  constructor(
    private readonly toursService: ApiToursService,
    private readonly tourTimingsService: ApiTourTimingsService,
    private readonly tourUserLocationService: ApiTourUserLocationService,
  ) {
  }

  ngOnInit() {
    this.toursService.GetToursForMap().pipe(extractContent()).subscribe(tours => {
      this.tours = tours;
      this.tourDateChanged();
      setTimeout(() => {
        this.centerMapOnTourMarkers(this.tours);
      }, 1000);

    });
  }

  selectTour(idStr?: string) {
    const id = Number(idStr);
    this.selectedTour = this.tours.find(x => x.id === id);
    if (this.selectedTour != null) {
      this.centerMapOnTourMarkers([this.selectedTour]);
    } else {
      this.selectedTour = null;
      this.selectedTourTiming = null;
      this.usersLocations = null;
      this.tourTimings = null;
      this.centerMapOnTourMarkers(this.tours);
    }
    this.loadTimings(this.selectedTour);
  }

  selectTourTiming(idStr?: string) {
    const id = Number(idStr);
    this.selectedTourTiming = this.tourTimings.find(x => x.id === id);
    if (this.selectedTourTiming != null) {
      this.loadUserLocations(this.selectedTourTiming);
    } else {
      this.selectedTourTiming = null;
      this.usersLocations = null;
      this.centerMapOnTourMarkers([this.selectedTour]);
    }

  }

  tourDateChanged() {
    if (this.tourDate == null) {
      this.filteredTours = this.tours;
    } else {
      this.filteredTours = this.tours.filter(x => new Date(x.date).getTime() === this.tourDate.getTime());
    }
  }

  private loadTimings(tour: TourMapViewModel) {
    if (tour != null) {
      this.tourTimingsService.GetForTour(tour.id)
        .pipe(extractContent()).subscribe(tourTimings => this.tourTimings = tourTimings.result);
    } else {
      this.tourTimings = null;
    }

  }

  private loadUserLocations(tourTiming: TourTimingViewModel) {
    if (tourTiming != null) {
      this.tourUserLocationService.GetTourUsersWithGuideLocations(tourTiming.pin)
        .pipe(extractContent()).subscribe(locations => {
        this.usersLocations = locations;
        this.centerMapOnUserLocationsMarkers(this.usersLocations);
      });
    } else {
      this.usersLocations = null;
    }

  }

  private selectUser(guid: string) {
    const user = this.usersLocations.find(x => x.userGuid === guid);
    if (user != null) {
      this.centerMapOnUserLocationsMarkers([user]);
    } else {
      this.centerMapOnUserLocationsMarkers(this.usersLocations);
    }
  }

  private getUserMapIconUrl(user: TourUserLocationViewModel) {
    return IconProvider.getIconUrl(user.guide != null ? 'guide' : 'guest');
  }

  private centerMapOnUserLocationsMarkers(users: TourUserLocationViewModel[]) {
    if (users.length > 0) {
      this.fitBounds = createBounds(users.map(x => createLatLng(x.location.lat, x.location.lng)));
    }
  }

  private centerMapOnTourMarkers(tours: TourMapViewModel[]) {
    if (tours.length > 0) {
      this.fitBounds = createBounds(tours.map(x => createLatLng(x.portLocation.lat, x.portLocation.lng)));
    }

  }
}
