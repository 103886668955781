import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BidsListComponent} from './bids-list/bids-list.component';
import {BidDetailsComponent} from './bid-details/bid-details.component';
import {FormsModule} from '@angular/forms';
import {NgxBidRoutingModule} from './bids-routing.module';
import {BidsCalendarComponent} from './bids-calendar/bids-calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DefdevAngularNebularModule, NebularModule} from 'defdev-angular-nebular';
import {OpsDocumentListComponent} from './ops-document-list/ops-document-list.component';
import {OpsDocumentDetailsComponent} from './ops-document-details/ops-document-details.component';

@NgModule({
  declarations: [
    BidsListComponent,
    BidDetailsComponent,
    BidsCalendarComponent,
    OpsDocumentListComponent,
    OpsDocumentDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxBidRoutingModule,
    FullCalendarModule,
    NebularModule,
    DefdevAngularNebularModule,
  ],

})
export class BidsModule {
}
