/* tslint:disable */
type BidStatus =
  'Requested' |
  'Sent' |
  'Appointed' |
  'Completed' |
  'Cancelled';
module BidStatus {
  export const REQUESTED: BidStatus = 'Requested';
  export const SENT: BidStatus = 'Sent';
  export const APPOINTED: BidStatus = 'Appointed';
  export const COMPLETED: BidStatus = 'Completed';
  export const CANCELLED: BidStatus = 'Cancelled';
  export function values(): BidStatus[] {
    return [
      REQUESTED,
      SENT,
      APPOINTED,
      COMPLETED,
      CANCELLED
    ];
  }
}

export { BidStatus }