import {Component} from '@angular/core';
import {ApiPortsService} from '../../../generated-api/services';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-ports',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class PortsComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.PORT,
    editModes: ['PAGE'],
    title: 'Ports',
  };

  constructor(
    readonly service: ApiPortsService) {
  }

}
