import {Component, OnInit, ViewChild} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput} from '@fullcalendar/core';
import {Router} from '@angular/router';
import {BidViewModel, PortViewModel, ShipViewModel, FilterRule} from '../../../generated-api/models';
import {ApiBidsService, ApiShipsService, ApiPortsService} from '../../../generated-api/services';
import {EditFieldDefinitionModel, extractContent, FormModel, getOptionForValue} from 'defdev-angular-nebular';
import {NbAccessChecker} from '@nebular/security';
import { start } from 'repl';

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

function getDateWith0Time(dateS: string) {
  const date = new Date(dateS);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
}

@Component({
  selector: 'ngx-bids-calendar',
  templateUrl: './bids-calendar.component.html',
  styleUrls: ['./bids-calendar.component.scss'],
})
export class BidsCalendarComponent implements OnInit {
  @ViewChild(FullCalendarComponent, { static: false })
  calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin, listPlugin, timeGridPlugin]; // important!
  filterModel: FormModel<BidViewModel>;
  calendarEvents: EventInput[] = [];
  baseFilterRule: FilterRule = {
    condition: 'and',
    rules: [],
  };
  d: Date = new Date(Date.now());
  startDate: Date = new Date(this.d.getFullYear(), this.d.getMonth(), 1,  0, 0, 0, 0);
  endDate: Date = new Date(new Date(this.d.setMonth(this.d.getMonth() + 1)).setDate(1));
  initialized = false;
  editFields: Array<EditFieldDefinitionModel> = [];

  constructor(readonly router: Router,
    readonly service: ApiBidsService,
    readonly portservice: ApiPortsService,
    readonly shipservice: ApiShipsService,
    readonly accessChecker: NbAccessChecker) {
  }

  ports: PortViewModel[];
  ships: ShipViewModel[];

  ngOnInit() {
    this.portservice.Get().pipe(extractContent()).subscribe(result => {
      this.ports = result;
      this.shipservice.Get().pipe(extractContent()).subscribe(shipresult => {
        this.ships = shipresult;
        this.initialized = true;
        this.refresh(this.baseFilterRule, this.startDate, this.endDate);
      })
    });
  }

  datesRender($event) {
    this.startDate = $event.view.currentStart;
    this.endDate = $event.view.currentEnd;
    this.refresh(this.baseFilterRule, this.startDate, this.endDate);
  }

  onFilter(filterRule: FilterRule) {
    this.baseFilterRule = filterRule;
    this.refresh(this.baseFilterRule, this.startDate, this.endDate);
  }

  async onAdd() {
    await this.router.navigate(['pages/operation/detail/-1?id=-1']);
  }

  async eventClick($event) {
    await this.router.navigate(['pages/operation/detail/' + $event.event.id]);
  }

  async uiClick($event) {
     if ($event.target && ($event.target.classList.contains("fc-content") || $event.target.classList.contains("fc-title"))) {
 
    } else {
      var date = this.calendarComponent.calendar.state.dateProfile.activeRange;
      this.refresh(this.baseFilterRule, date.start, date.end);
    }
  }

  private formatDateTimeForApi(d: Date) {
    return d.toISOString();
  }

  private refresh(filterRule: FilterRule, startDate: Date, endDate: Date) {
    if (!this.initialized && this.filterModel != null) {
      this.initialized = true;
    }

    if (this.initialized) {
      const dateFilterRule: FilterRule = {
        condition: 'and',
        rules: [],
      };

       dateFilterRule.rules.push({
         field: 'startDate',
         value: this.formatDateTimeForApi(startDate),
         operator: '>=',
         type: 'date',
         condition: 'and',
       });
       dateFilterRule.rules.push({
         field: 'endDate',
         value: this.formatDateTimeForApi(endDate),
         operator: '<=',
         type: 'date',
         condition: 'and',
       });
      dateFilterRule.rules.push({
        type: 'boolean',
        operator: '=',
        field: 'bidInvoiced',
        value: 'false',
      });
      dateFilterRule.rules.push({
        type: 'BidStatus',
        operator: 'not_in',
        field: 'status',
        value: ['Completed', 'Cancelled'],
      });

      this.service.GetWithFilter(dateFilterRule).pipe(extractContent())
        .subscribe(bids => this.loadEventsBasedOnBids(bids));
    }
  }

  private loadEventsBasedOnBids(bids: Array<BidViewModel>) {
    const newEvents: EventInput[] = [];
    bids.forEach(bid => {
      console.log({
        id: bid.id,
        title: this.getBidTitle(bid),
        start: getDateWith0Time(bid.startDate),
        end: addMinutes(getDateWith0Time(bid.endDate), 23 * 60 + 59),
        color: this.getBidColor(bid)
      });
      newEvents.push({
        id: bid.id,
        title: this.getBidTitle(bid),
        start: getDateWith0Time(bid.startDate),
        end: addMinutes(getDateWith0Time(bid.endDate), 23 * 60 + 59),
        color: this.getBidColor(bid)
      });
    });
    this.calendarEvents = newEvents;
  }

  private getBidTitle(bid: BidViewModel) {
    let result = '';
    try {
      const portOption = this.ports.find(x => x.id === bid.portId).name;
      const shipOption = this.ships.find(x => x.id === bid.shipId).name;

      if (portOption != null) {
        result = portOption + ' ';
      }
      if (shipOption != null) {
        result += shipOption;
      }
    } catch (e) {
      
    }
    return result;
  }

  private getOptionFor(parameterName: string, value: any) {
    return this.ports.find(x => x.id === value).name;
  }


  private getBidColor(bid: BidViewModel): string {
    return bid.officeColor;
  }
}
