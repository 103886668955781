import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BidViewModel} from '../../../generated-api/models/bid-view-model';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {flatMap, map} from 'rxjs/operators';
import {
  ApiBidsService,
  ApiConfigService,
  ApiOpsDocumentService,
  ApiTourTimingsService
} from '../../../generated-api/services';
import {
  ConfigBasedTableComponent, DialogService,
  DynamicFormComponent,
  extractContent,
  FormMode,
  FormModel,
  getDefaultId,
  ITableConfig, RouterHelperService,
} from 'defdev-angular-nebular';
import {OpsDocumentViewModel} from '../../../generated-api/models/ops-document-view-model';
import {ModelTypes} from '../../../generated-api/models';
import {NbTabsetComponent} from '@nebular/theme';
import {AppModelMapperService} from '../../../app-model-mapper.service';
import { Observable, Subscription } from 'rxjs';
import { UtilityService } from '../../../utils.service';

export function replaceUrlParam(url, paramName, paramValue) {


  if (paramValue == null) {
    paramValue = '';
  }

  const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2');
  }

  url = url.replace(/[?#]$/, '');
  return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
}

@Component({
  selector: 'ngx-ops-document-details',
  templateUrl: './ops-document-details.component.html',
  styles: [],
})
export class OpsDocumentDetailsComponent implements OnInit {
  formModel: FormModel<OpsDocumentViewModel>;
  tabNames: Array<string> = ['guides', 'notes', 'tours', 'transports', 'venues'];
  @ViewChild(DynamicFormComponent, {static: false})
  ngxForm: DynamicFormComponent<OpsDocumentViewModel>;

  private eventsSubscription: Subscription;

  cloning: boolean = false;
  selectedIds: number[];
  message: string = "";

  actionsTableConfig: ITableConfig = {
    modelType: ModelTypes.OPS_DOCUMENT_ACTION,
    title: 'Actions',
    editModes: ['INLINE'],
    showActionsToolbar: true,
    selectionMode: 'single',
    addButtonEnabled: true,
    deleteButtonEnabled: false,
    referencedProperties: [{field: 'opsDocumentId', value: () => this.formModel.object.id, type: 'integer'}],
    additionalSettings: [{
      name: 'pager',
      value: {
        display: false,
        perPage: 100,
        perPageSelect: [10, 20, 50, 100],
      },
    }],
  };
  tourTimingsConfig: ITableConfig = {
    title: 'Tours',
    modelType: ModelTypes.TOUR_TIMING,
    referencedProperties: [{field: 'opsDocumentId', type: 'integer', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    deleteButtonEnabled: true,
    filterType: 'basic',
    editModes: ['PAGE', 'INLINE'],
    selectionMode: 'single',
    dataChangedListener: (type, obj) => this.transportTable.refresh(),
    extraToolbarActions: [{ icon: "copy-outline", name: "Clone", title: "Clone" }],
    queryParamsFunction: () => {
      return {
        'reportTime': this.formModel.object.date,
        'depart': this.formModel.object.date,
        'arrive': this.formModel.object.date,
      };
    },
    customTableActions: [
      {name: 'moveup', title: '<i class="nb-arrow-up" title="Up"></i>'},
      {name: 'movedown', title: '<i class="nb-arrow-down" title="Down"></i>'},
    ],
  };
  transportBookingsConfig: ITableConfig = {
    title: 'Transport',
    modelType: ModelTypes.TRANSPORT_BOOKING,
    referencedProperties: [{field: 'opsDocumentId', type: 'integer', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE', 'PAGE'],
    queryParamsFunction: () => {
      return {'date': this.formModel.object.date};
    },
  };
  venueBookingsConfig: ITableConfig = {
    title: 'Venue',
    modelType: ModelTypes.VENUE_BOOKING,
    referencedProperties: [{field: 'opsDocumentId', type: 'integer', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    addButtonEnabled: true,
    filterType: 'basic',
    editModes: ['INLINE', 'PAGE'],
    queryParamsFunction: () => {
      return {'date': this.formModel.object.date};
    },
  };
  guideBookingsConfig: ITableConfig = {
    title: 'Guide bookings',
    modelType: ModelTypes.GUIDE_BOOKING,
    referencedProperties: [{field: 'opsDocumentId', type: 'integer', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE', 'PAGE'],
    queryParamsFunction: () => {
      return {'date': this.formModel.object.date};
    },
  };
  shipItienartyConfig: ITableConfig = {
    title: 'Ship Itinerary',
    modelType: ModelTypes.SHIP_ITINERY,
    referencedProperties: [{field: 'bidId', type: 'integer', value: () => this.formModel.object.bidId}],
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE', 'MODAL'],
    queryParamsFunction: () => {
      return {
        'date': this.formModel.object.date,
        'shipId': this.formModel.object.shipId,
      };
    },

  };
  OpsDocumentsTableConfig: ITableConfig = {
    modelType: ModelTypes.OPS_DOCUMENT,
    title: null,
    showBulkUpdate: false,
    editModes: [],
    editButtonEnabled: false,
    deleteButtonEnabled: false,
    addButtonEnabled: false,
    selectionMode: "multi",
    exportableColumns: [],
    hiddenColumns: ["toursCount", "actionsStatus", "invoiced"],
    defaultFiltersFunction: () => [
      {
        type: 'boolean',
        operator: '=',
        field: 'invoiced',
        value: 'false',
      },
      {
        type: 'boolean',
        operator: '=',
        field: 'cancelled',
        value: 'false',
      },
    ],
  };

  @ViewChild('tabsetComponent', {static: false})
  tabsetComponent: NbTabsetComponent;
  @ViewChild('tourTimingTable', {static: false})
  tourTimingTable: ConfigBasedTableComponent;
  @ViewChild('transportTable', {static: false})
  transportTable: ConfigBasedTableComponent;
  @ViewChild('CloneToTable', { static: false })
  CloneToTableRef: ConfigBasedTableComponent;
  @ViewChild('venueTable', { static: false })
  venueTable: ConfigBasedTableComponent;
  @ViewChild('guidesBookingTable', { static: false })
  guidesTable: ConfigBasedTableComponent;
  @ViewChild('notesTable', { static: false })
  notesTable: ConfigBasedTableComponent;

  initialzied = false;

  constructor(private readonly router: Router,
              private readonly location: Location,
              private readonly routeHelper: RouterHelperService,
              private readonly service: ApiOpsDocumentService,
              private readonly tourTimingsService: ApiTourTimingsService,
              private readonly appModelMapper: AppModelMapperService,
              private readonly bidsService: ApiBidsService,
              private readonly configService: ApiConfigService,
              private readonly dialogService: DialogService,
              private util: UtilityService) {
  }

  onCustomAction(event) {
    if (event.action === 'moveup') {
      const id = event.data.id;
      // Update order by minus-ing 1 to the order column
      this.tourTimingsService.MoveUp({ id: id, opsDocumentId: this.formModel.object.id }).subscribe(() => {
        //  Refresh table data
        this.tourTimingTable.refresh();
      });
    } else if (event.action === 'movedown') {
      const id = event.data.id;
      // Update order by plus-ing 1 from the  order column
      this.tourTimingsService.MoveDown({ id: id, opsDocumentId: this.formModel.object.id }).subscribe(() => {
        //  Refresh table data
        this.tourTimingTable.refresh();
      });
    } else if (event.action == "Clone") {
      this.selectedIds = [];
      this.tourTimingTable.tableComponent.table._dataSource.getAll().then(x => {
        for (var i = 0; i < x.length; i++)
          this.selectedIds.push(x[i].id);
          if (this.selectedIds.length > 0) this.cloning = true;
      });
    }
  }

  public cloneIntoOpsDocuments() {
    var selectedOpsDocuments = this.CloneToTableRef.tableComponent.table.selectedRows;
    var selectedOpsIds: number[] = [];

    for (var i = 0; i < selectedOpsDocuments.length; i++)
      selectedOpsIds.push(selectedOpsDocuments[i].id);

    if (selectedOpsIds.length > 0) {
      this.tourTimingsService.CloneMulti({
        opDocumentIds: selectedOpsIds,
        tourIds: this.selectedIds
      }).subscribe(x => {
        this.message = "Clone completed successfully";
      });
    } else {
      this.message = "Nothing to clone";
    }

    this.cloning = false;
  }

  cancelBid() {
    this.configService.GetEditFormModel({id: '', modelType: ModelTypes.BID_CANCELLATION})
      .pipe(extractContent())
      .subscribe(formModel => {
        this.dialogService.showDynamicEditorDialog({formModel, header: 'Do you want to cancel'})
          .onConfirm((result) => {
            this.bidsService.GetWithEditData({id: this.formModel.object.bidId})
              .pipe(extractContent())
              .subscribe(bidFormModel => {
                bidFormModel.object.cancellation = result.formModel.object;
                this.bidsService.AddOrUpdate(bidFormModel.object)
                  .subscribe(() => {
                    this.onCancel();
                  });
              });

          });
      });
  }

  ngOnInit() {
    this.routeHelper.getParamFromRoute('id')
      .pipe(
        flatMap(id => this.service.GetWithEditData({
          id: getDefaultId(id),
        })))
      .pipe(extractContent())
      .subscribe(formModel => {
        this.formModel = formModel;

        var tab = document.querySelector(".nav-tabs .nav-link.active .flex-fill");
        var clientname = "";
        var clients = this.formModel.formConfig.formGroups[0].editFields[4].options;
        for (var j = 0; j < clients.length; j++) {
          if (clients[j].optionId.toString() == this.formModel.object.clientId.toString()) {
            clientname = clients[j].optionText;
            break;
          }
        }
        var d = new Date(Date.parse(this.formModel.object.date));
        tab.innerHTML = "OD " + clientname.substring(0, 3) + " " + d.getDate() + "/" + this.pad(d.getMonth() +1);


        setTimeout(() => {
          // go to correct tab
          const urlParams = new URLSearchParams(location.hash);
          const tab = urlParams.get('tab') || this.tabNames.find(x => location.hash.indexOf('tab=' + x) > -1);
          if (tab) {
            const tabToShow = this.tabsetComponent.tabs.find(x => x.route === tab);
            if (tabToShow) {
              this.tabsetComponent.selectTab(tabToShow);
            }
          }
          this.initialzied = true;
        }, 250);
      });

    this.eventsSubscription = this.util.eventsSubject.subscribe((d) => {
      console.log("event occured", d);
      switch (d.toLowerCase()) {
        case "tourtimingdetailscomponent":
          this.tourTimingTable.refresh();
          break;
        case "venue_booking":
          this.venueTable.refresh();
          break;
        case "transport_booking":
          this.transportTable.refresh();
          break;
        case "guide_booking":
          this.guidesTable.refresh();
          break;
        case "notes":
          this.notesTable.refresh();
          break;
      }
    });
  }

  public pad(num: Number) {
    if (num.toString().length == 1) return "0" + num.toString();
    return num;
  }

  public save(closeCurrentPage: boolean = true): void {
    if (this.formModel.object.id > 0) {
      this.service.Put(this.formModel.object)
        .pipe(extractContent()).subscribe(x => this.afterSuccess(x, closeCurrentPage));
    } else {
      this.service.Post(this.formModel.object)
        .pipe(extractContent()).subscribe(x => this.afterSuccess(x, closeCurrentPage));
    }

  }

  private onCancel() {
    //this.goToLastPageOrDefault();
    this.location.go("/pages/operation/ops-document/list");
  }

  protected afterSuccess(x: BidViewModel, closeCurrentPage: boolean = true) {
    if (closeCurrentPage) {
      //this.goToLastPageOrDefault();
      this.location.go("/pages/operation/ops-document/list");
    } else {
      if (this.formModel.formConfig.formMode !== FormMode.EDIT) {
        this.appModelMapper.navigateToDetails(this.formModel.formConfig.modelType, this.formModel.object['id'], []);
      }
    }
  }

  private goToLastPageOrDefault() {
    this.location.back();
  }

  tabChanged(tab: any) {
    if (this.initialzied) {
      location.hash = replaceUrlParam(location.hash, 'tab', tab.route);
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
