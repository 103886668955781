import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {ShipViewModel} from '../../../generated-api/models/ship-view-model';
import {ApiShipsService} from '../../../generated-api/services';
import {
  ApiCrudService,
  BaseDynamicFormComponent,
  ITableConfig,
  MODEL_MAPPER_SERVICE,
  ModelMapperService,
  RouterHelperService,
} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-ship-details',
  templateUrl: './ship-details.component.html',
  styleUrls: ['./ship-details.component.scss'],
})
export class ShipDetailsComponent extends BaseDynamicFormComponent<ShipViewModel> {


  shipItienartyConfig: ITableConfig = {
    title: 'Itineries',
    modelType: ModelTypes.SHIP_ITINERY,
    addButtonEnabled: false,
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE'],
    customTableActions: [{name: 'bid', title: '<i class="nb-home" title="View bid"></i>'}],
  };

  constructor(
    protected readonly service: ApiShipsService,
    routeHelper: RouterHelperService,
    @Inject(MODEL_MAPPER_SERVICE)
    protected readonly crudMapperApiService: ModelMapperService,
    readonly router: Router,
  ) {
    super(routeHelper, crudMapperApiService);
  }


  onCustomAction(event) {
    this.router.navigate(['pages/operation/detail/' + event.data.bidId]);
  }

  protected

  getService()
    :
    ApiCrudService<ShipViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.SHIP);
  }


}
