import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ModelTypes, TourViewModel} from '../../../generated-api/models';
import {Router} from '@angular/router';
import {ApiToursService, ApiTourTimingsService} from '../../../generated-api/services';


import {
  extractContent,
  FormModel,
  getDefaultId,
  getDefaultTableSettingsFromConfig,
  RouterHelperService
} from 'defdev-angular-nebular';
import {withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'ngx-tour-details',
  templateUrl: './tour-details.component.html',
  styleUrls: ['./tour-details.component.scss'],
})
export class TourDetailsComponent implements OnInit {

  formModel: FormModel<TourViewModel>;

  tableSettings = getDefaultTableSettingsFromConfig({
    modelType: ModelTypes.TOUR_TIMING,
    showBulkUpdate: false,
    title: 'Tour timings',
    editModes: ['PAGE', 'INLINE'],
    referencedProperties: [{field: 'tourId', type: 'integer', value: () => this.formModel.object.id}],
  });


  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly routeHelper: RouterHelperService,
    private readonly tourService: ApiToursService,
    private readonly tourTimingService: ApiTourTimingsService,
  ) {
  }

  ngOnInit() {
    this.routeHelper.getParamsFromRoute().subscribe(params => {
      this.tourService.GetWithEditData({
        id: getDefaultId(params['id']),
      })
      .pipe(extractContent())
      .subscribe(details => {
        this.formModel = details;
      });
    });
  }

  public save(): void {
    if (this.formModel.object.id > 0) {
      this.tourService.Put(this.formModel.object).pipe(extractContent()).subscribe(x => this.afterSuccess(x));
    } else {
      this.tourService.Post(this.formModel.object).pipe(extractContent()).subscribe(x => this.afterSuccess(x));
    }
  }

  private onCancel() {
    this.goToLastPageOrDefault();
  }

  private afterSuccess(x: TourViewModel) {
    this.goToLastPageOrDefault();
  }

  private goToLastPageOrDefault() {
    this.location.back();
  }

}
