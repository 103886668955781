/* tslint:disable */
type ReasonForCancellation =
  'Invoice' |
  'InvoiceButCancelled' |
  'Cancelled';
module ReasonForCancellation {
  export const INVOICE: ReasonForCancellation = 'Invoice';
  export const INVOICE_BUT_CANCELLED: ReasonForCancellation = 'InvoiceButCancelled';
  export const CANCELLED: ReasonForCancellation = 'Cancelled';
  export function values(): ReasonForCancellation[] {
    return [
      INVOICE,
      INVOICE_BUT_CANCELLED,
      CANCELLED
    ];
  }
}

export { ReasonForCancellation }