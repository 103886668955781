import { Component, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TourUserLocationViewModel } from '../../../generated-api/models/tour-user-location-view-model';
import { TourTimingViewModel } from '../../../generated-api/models/tour-timing-view-model';
import {
  ApiTourTimingItineryService,
  ApiTourTimingsService,
  ApiTourUserLocationService,
  ApiVenueBookingService
} from '../../../generated-api/services';
import { Observable, of, Subscription } from 'rxjs';
import {
  ApiCrudService,
  BaseDynamicFormComponent,
  ConfigBasedTableComponent,
  DialogService,
  extractContent,
  FormModel,
  getDefaultTableSettingsFromConfig,
  ITableConfig,
  MODEL_MAPPER_SERVICE,
  ModelMapperService,
  RouterHelperService,
  SmartTableSimpleDS,
  DocumentsComponent
} from 'defdev-angular-nebular';
import { IconProvider } from '../../../utils/icon-provider';
import { Location } from '@angular/common';
import { ModelTypes } from '../../../generated-api/models';
import { UtilityService } from '../../../utils.service';

@Component({
  selector: 'ngx-tour-timing-details',
  templateUrl: './tour-timing-details.component.html',
  styleUrls: ['./tour-timing-details.component.scss'],
})
export class TourTimingDetailsComponent extends BaseDynamicFormComponent<TourTimingViewModel> {

  cloning: boolean = false;
  tourItineriesTableConfig: ITableConfig = {
    modelType: ModelTypes.TOUR_TIMING_ITINERY,
    title: 'Itienaries',
    referencedProperties: [{ field: 'tourTimingId', value: () => this.formModel.object.id, type: 'integer' }],
    extraToolbarActionsInOppositeDirection: [
      { name: 'move_timing', title: 'Move timing by time', icon: 'clock-outline' },
    ],
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE', 'MODAL']
  };
  venueBookingsTableRef: ConfigBasedTableComponent;
  CloneToTableRef: ConfigBasedTableComponent;
  // it is not loaded because tab with this table is not visible
  // so after initialized i have to link to it
  // users cant be loaded dynamically
  tourUsersTableSettings = getDefaultTableSettingsFromConfig({
    title: 'Users',
    modelType: ModelTypes.TOUR_LOCATION,
    addButtonEnabled: false,
    editButtonEnabled: false,
    deleteButtonEnabled: false,
  });
  mapProp = {
    lat: 9.234960,
    lon: 20.357078,
    zoom: 3,
    mapTypeId: 'hybrid',
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: true,
    scaleControl: true,
  };
  users: Array<TourUserLocationViewModel> = [];
  bookingsConfig: ITableConfig = {
    modelType: ModelTypes.BOOKING,
    title: 'Bookings',
    referencedProperties: [{ type: 'integer', field: 'tourTimingId', value: () => this.formModel.object.id }],
    addButtonEnabled: true,
    deleteButtonEnabled: true,
    showBulkUpdate: false,
    editModes: ['PAGE'],
  };

  OpsDocumentsTableConfig: ITableConfig = {
    modelType: ModelTypes.OPS_DOCUMENT,
    title: null,
    showBulkUpdate: false,
    editModes: [],
    editButtonEnabled: false,
    deleteButtonEnabled: false,
    addButtonEnabled: false,
    selectionMode: "multi",
    exportableColumns: [],
    hiddenColumns: ["toursCount", "actionsStatus", "invoiced"],
    defaultFiltersFunction: () => [
      {
        type: 'boolean',
        operator: '=',
        field: 'invoiced',
        value: 'false',
      },
      {
        type: 'boolean',
        operator: '=',
        field: 'cancelled',
        value: 'false',
      },
    ],
  };

  numberToClone: number = 1;
  message: string = "";

  venueBookingsConfig: ITableConfig = {
    title: 'Itienaries',
    modelType: ModelTypes.VENUE_BOOKING,
    referencedProperties: [
      { field: 'tourTimingId', value: () => this.formModel.object.id, type: 'integer' },
      { field: 'opsDocumentId', value: () => this.formModel.object.opsDocumentId, type: 'integer' },
    ],
    queryParamsFunction: () => {
      return {
        'date': this.formModel.object.depart,
      };
    },
    extraToolbarActionsInOppositeDirection: [
      { name: 'move_timing', title: 'Move timing by time', icon: 'clock-outline' },
    ],
    showBulkUpdate: false,
    filterType: 'basic',
    editModes: ['INLINE', 'PAGE'],
    visibleColumns: ['venueId', 'VenueOther', 'date', 'timeFrom', 'timeTo', 'venueDescriptionId', 'status']
  };
  private tourUsersDataSource: SmartTableSimpleDS<TourUserLocationViewModel>;

  constructor(
    routeHelper: RouterHelperService,
    private readonly router: Router,
    private readonly tourTimingService: ApiTourTimingsService,
    private readonly tourItineryService: ApiTourTimingItineryService,
    private readonly venueBookingService: ApiVenueBookingService,
    private readonly location: Location,
    private readonly dialogsService: DialogService,
    private util: UtilityService,
    @Inject(MODEL_MAPPER_SERVICE) protected crudMapperService: ModelMapperService
  ) {
    super(routeHelper, crudMapperService);
  }

  // staic false means check it after check detetor and you have to make it setter
  @ViewChild(ConfigBasedTableComponent, { static: false })
  set venueBookingsTable(val) {
    this.venueBookingsTableRef = val;
  };

  @ViewChild(ConfigBasedTableComponent, { static: false })
  set CloneToTable(val) {
    this.CloneToTableRef = val;
  };

  loadUsers(details: FormModel<TourTimingViewModel>) {
  }

  tableCustomActionHandler(event: any) {
    if (event.action === 'move_timing') {
      const table = this.venueBookingsTableRef;
      this.dialogsService
        .showInputEditorDialog({
          type: 'number',
          header: 'Move bookings by minutes',
          label: 'Minutes',
          input: 15 + '',
        })
        .onConfirm(result => {
          this.venueBookingService.MoveBookings({ minutes: Number(result.input), tourTimingId: this.formModel.object.id })
            .subscribe(() => {
              table.dataSource.refresh();
            });
        });
    }

  }

  protected loaded(details: FormModel<TourTimingViewModel>): void {
    super.loaded(details);
    if (!details.object.completed && details.object.id > 0) {
      this.loadUsers(details);
    }
  }

  public save(closePage: boolean = true): void {
    this.getService().AddOrUpdate(this.formModel.object)
      .pipe(extractContent())
      .subscribe(x => {
        this.formModel.object = x;
        this.util.eventsSubject.next("TourTimingDetailsComponent");
        this.afterSuccess(x, this.shouldClose(closePage, x));
      });
  }

  public clone() {
    this.cloning = true;
  }

  private eventsSubscription: Subscription;
  public localInit() {
    this.eventsSubscription = this.util.eventsSubject.subscribe((d) => {
      if (d == "TourTimingDetailsComponent") this.venueBookingsTableRef.refresh();
    });
  }

  public cloneIntoOpsDocuments() {
    this.tourTimingService.Clone({
      times: this.numberToClone,
      tourId: this.formModel.object.id
    }).subscribe(x => {
      this.message = "Tour cloned successfully";
    });

    this.cloning = false;
  }

  protected getService(): ApiCrudService<TourTimingViewModel> {
    return this.tourTimingService;
  }
}
