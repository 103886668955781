import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TourDetailsComponent} from './tour-details/tour-details.component';
import {TourListComponent} from './tour-list/tour-list.component';
import {NgxTourRoutingModule} from './tour-routing.module';
import {FormsModule} from '@angular/forms';
import {TourTimingDetailsComponent} from './tour-timing-details/tour-timing-details.component';
import {TourListMapComponent} from './tour-list-map/tour-list-map.component';
import {AgmCoreModule} from '@agm/core';
import {NgPipesModule} from 'ngx-pipes';
import {NgSelectModule} from '@ng-select/ng-select';
import {DefdevAngularNebularModule, NebularModule} from 'defdev-angular-nebular';
import {TourTimingListComponent} from './tour-timing-list/tour-timing-list.component';

@NgModule({
  declarations: [
    TourDetailsComponent,
    TourListComponent,
    TourTimingDetailsComponent,
    TourListMapComponent,
    TourTimingListComponent,
  ],
  imports: [
    CommonModule,
    NgxTourRoutingModule,
    FormsModule,
    AgmCoreModule,
    NgPipesModule,
    NgSelectModule,
    NebularModule,
    DefdevAngularNebularModule,
  ]
})
export class TourModule {
}
