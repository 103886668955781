import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from '../pages.component';
import {TourListComponent} from './tour-list/tour-list.component';
import {TourDetailsComponent} from './tour-details/tour-details.component';
import {TourTimingDetailsComponent} from './tour-timing-details/tour-timing-details.component';
import {TourListMapComponent} from './tour-list-map/tour-list-map.component';
import {TourTimingListComponent} from './tour-timing-list/tour-timing-list.component';
import {DETAIL_COMPONENT_ROUTE, LIST_COMPONENT_ROUTE} from '../../constants';
import {TabPagesComponent} from "../tab-pages.component";

const routes: Routes = [{
  path: 'tour',
  children: [
    {
      path: LIST_COMPONENT_ROUTE,
      component: TourListComponent,
    },
    {
      path: 'map',
      component: TourListMapComponent,
    },
    {
      path: DETAIL_COMPONENT_ROUTE,
      component: TourDetailsComponent,
    },
    {
      path: DETAIL_COMPONENT_ROUTE + '/:id',
      component: TourDetailsComponent,
    },
    {
      path: 'tour-timing/' + DETAIL_COMPONENT_ROUTE,
      component: TourTimingDetailsComponent,
    },
    {
      path: 'tour-timing/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: TourTimingDetailsComponent,
    },
    {
      path: 'tour-timing/' + LIST_COMPONENT_ROUTE,
      component: TourTimingListComponent,
    },
    {
      path: '',
      redirectTo: 'tours',
      pathMatch: 'full',
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxTourRoutingModule {
}
