import {Component} from '@angular/core';
import {ApiCrudService, BaseDynamicFormComponent, ITableConfig} from 'defdev-angular-nebular';
import {VenueViewModel} from '../../../generated-api/models/venue-view-model';
import {ModelTypes} from '../../../generated-api/models/model-types';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ngx-venue-details',
  template: `
    <nb-card>
      <nb-card-header>
        Venue & Supplier Information
      </nb-card-header>
    </nb-card>
    <ng-container *ngIf="formModel">
      <ngx-dynamic-form #ngxForm [formModel]="formModel">

      </ngx-dynamic-form>
      <!--show only if user existed before-->
      <!--      ic can be here o-->
      <ngx-config-based-table-component [tableConfig]="contactsConfig"
                                        *ngIf="formModel?.object?.id > 0"></ngx-config-based-table-component>
      <ngx-config-based-table-component [tableConfig]="bookingsConfig"
                                        *ngIf="formModel?.object?.id > 0"></ngx-config-based-table-component>
      <nb-card>
        <nb-card-footer>
          <button nbButton status="basic" class="mr-2" (click)="onCancel()">Cancel</button>
          <button nbButton status="success" class="mr-2"
                  (click)="ngxForm.updateModel();save()"
                  [disabled]="ngxForm.form.invalid">Save
          </button>
          <button class="mr-2 " (click)="ngxForm.updateModel();save(false)" [disabled]="ngxForm.form.invalid"
                  [status]="'success'" hero
                  nbButton
          >Save and continue
          </button>
        </nb-card-footer>
      </nb-card>

    </ng-container>

  `,

})
export class VenueDetailsComponent extends BaseDynamicFormComponent<VenueViewModel> {

  bookingsConfig: ITableConfig = {
    modelType: ModelTypes.VENUE_BOOKING,
    title: 'Bookings',
    referencedProperties: [{type: 'integer', field: 'venueId', value: () => this.formModel.object.id}],
    addButtonEnabled: false,
    deleteButtonEnabled: false,
    showBulkUpdate: false,
    editModes: ['PAGE', 'INLINE'],
    selectionMode: 'single',
  };
// contatcs table
  contactsConfig: ITableConfig = {
    modelType: ModelTypes.VENUE_CONTACT,
    title: 'Contacts',
    referencedProperties: [{type: 'integer', field: 'venueId', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    selectionMode: 'single',
    editModes: ['MODAL', 'INLINE'],
  };

  protected getService(): ApiCrudService<VenueViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.VENUE);
  }
}
