import {Component} from '@angular/core';
import {ModelTypes, PortAgencyViewModel} from '../../../generated-api/models';
import {ApiCrudService, BaseDynamicFormComponent, ITableConfig} from 'defdev-angular-nebular';

@Component({
  selector: 'ngx-port-agency-details',
  templateUrl: './port-agency-details.component.html',
  styleUrls: ['./port-agency-details.component.scss'],
})
export class PortAgencyDetailsComponent extends BaseDynamicFormComponent<PortAgencyViewModel> {

  tableConfig: ITableConfig = {
    editModes: ['MODAL'],
    modelType: ModelTypes.PORT_AGENT,
    title: 'Agents',
    referencedProperties: [{field: 'agencyId', type: 'integer', value: () => this.formModel.object.id}],
  };


  protected getService(): ApiCrudService<PortAgencyViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.PORT_AGENCY);
  }

}
