import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';
import {ApiBidsService} from "../../../generated-api/services/api-bids.service";

@Component({
  selector: 'ngx-reports',
  template: `
    <input type="text" [(ngModel)]="this.startnumber" type='number' />
    <button class="mr-2 " (click)="generatePONumberReport()" [status]="'success'" hero nbButton>PO Number report
    </button>`,
})
export class ReportsComponent {
  startnumber: number;

  constructor(private bidService: ApiBidsService) {
  }

  generatePONumberReport() {

    this.bidService.POReport(this.startnumber)
      .subscribe(response => {
        window.open(response.content.filePath, '_blank');
      });
  }

}
