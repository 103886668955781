/* tslint:disable */
type TransportVenueBookingStatus =
  'To_book' |
  'Booked' |
  'Allocated' |
  'To_cancel' |
  'Cancelled' |
  'Not_available' |
  'Re_book';
module TransportVenueBookingStatus {
  export const TO_BOOK: TransportVenueBookingStatus = 'To_book';
  export const BOOKED: TransportVenueBookingStatus = 'Booked';
  export const ALLOCATED: TransportVenueBookingStatus = 'Allocated';
  export const TO_CANCEL: TransportVenueBookingStatus = 'To_cancel';
  export const CANCELLED: TransportVenueBookingStatus = 'Cancelled';
  export const NOT_AVAILABLE: TransportVenueBookingStatus = 'Not_available';
  export const RE_BOOK: TransportVenueBookingStatus = 'Re_book';
  export function values(): TransportVenueBookingStatus[] {
    return [
      TO_BOOK,
      BOOKED,
      ALLOCATED,
      TO_CANCEL,
      CANCELLED,
      NOT_AVAILABLE,
      RE_BOOK
    ];
  }
}

export { TransportVenueBookingStatus }