import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-tour-offices',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class OfficesComponent {
  tableConfig: ITableConfig = {
    modelType: ModelTypes.OFFICE,
    title: 'Offices',
    showBulkUpdate: false,
  };


  constructor() {
  }


}
