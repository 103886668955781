import {Component, Inject} from '@angular/core';
import {NbAccessChecker} from '@nebular/security';
import {MENU_ITEMS} from './pages-menu';
import {NbMenuItem, NbSearchService} from '@nebular/theme';
import {environment} from '../../environments/environment';
import {DialogService, HttpLoaderService, MODEL_MAPPER_SERVICE, ModelMapperService} from 'defdev-angular-nebular';
import {forkJoin} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
  selector: 'ngx-tab-pages',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class TabPagesComponent {

}
