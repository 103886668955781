import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-tour-list',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class TourListComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.TOUR,
    title: 'Tours',
    addButtonEnabled: true,
  };

  constructor() {
  }

}
