import {NgModule} from '@angular/core';

import {PagesComponent} from './pages.component';
import {PagesRoutingModule} from './pages-routing.module';
import {SettingsModule} from './settings/settings.module';
import {TourModule} from './tour/tour.module';
import {BidsModule} from './bids/bids.module';
import {DefdevAngularNebularModule, ThemeModule} from 'defdev-angular-nebular';
import {TabPagesComponent} from "./tab-pages.component";

const PAGES_COMPONENTS = [
  TabPagesComponent,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    SettingsModule,
    BidsModule,
    TourModule,
    DefdevAngularNebularModule,
    ThemeModule,
  ],
  declarations: [
    ...PAGES_COMPONENTS,
  ],
})
export class PagesModule {
}
