import {NbMenuItem} from '@nebular/theme';
import {LIST_COMPONENT_ROUTE} from '../constants';
import {PERMISSION_LIST} from 'defdev-angular-nebular';
import {ModelTypes} from '../generated-api/models';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Calendar',
    icon: 'globe-outline',
    data: {
      permission: 'calendar',
      resource: ModelTypes.BID,
    },
    children: [
      {
        title: 'Calendar',
        link: '/pages/operation/calendar',
        data: {
          permission: 'calendar',
          resource: ModelTypes.BID,
        },
      },
      {
        title: 'List View',
        link: '/pages/operation/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.BID,
        },
      },
      {
        title: 'Ops documents',
        link: '/pages/operation/ops-document/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.OPS_DOCUMENT,
        },
      },
      {
        title: 'Venues & Suppliers',
        link: '/pages/settings/venue/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.VENUE,
        },
      },
      {
        title: 'Transport',
        link: '/pages/settings/transport/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.TRANSPORT,
        },
      },
      {
        title: 'Guides',
        link: '/pages/settings/guide/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.GUIDE,
        },
      },
    ],
  },
  {
    title: 'Excursions Ltd App',
    icon: 'navigation-outline',
    data: {
      permission: PERMISSION_LIST,
      resource: ModelTypes.TOUR,
    },
    children: [
      {
        title: 'Tours list',
        link: '/pages/tour/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.TOUR,
        },
      },
      //
      {
        title: 'Tour timings list',
        link: '/pages/tour/tour-timing/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.TOUR_TIMING,
        },
      },
      {
        title: 'Tours map',
        link: '/pages/tour/map',
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.TOUR_TIMING,
        },
      },
      {
        title: 'Default tour documents',
        link: '/pages/settings/default-documents',
        data: {
          permission: PERMISSION_LIST,
          resource: 'default-documents',
        },
      },
      {
        title: 'Image library',
        link: '/pages/settings/image-library',
        data: {
          permission: PERMISSION_LIST,
          resource: 'image-library',
        },
      },
    ],
  },
  {
    title: 'Users',
    icon: 'person-outline',
    data: {
      permission: PERMISSION_LIST,
      resource: ModelTypes.USER,
    },
    children: [
      {
        title: 'Staff',
        link: '/pages/settings/user/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.USER,
        },
      },
      {
        title: 'Port Coordinators',
        link: '/pages/settings/user/port-coordinators',
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.USER,
        },
      },
    ],
  },
  {
    title: 'Setup',
    icon: 'settings-outline',
    data: {
      permission: 'manage',
      resource: 'setup',
    },
    children: [
      {
        title: 'Clients',
        link: '/pages/settings/client/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.CLIENT,
        },
      },
      {
        title: 'Offices',
        link: '/pages/settings/office/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.OFFICE,
        },
      },
      {
        title: 'Cruise ships',
        link: '/pages/settings/ship/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.SHIP,
        },
      },
      {
        title: 'Ports',
        link: '/pages/settings/port/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.PORT,
        },
      },
      {
        title: 'Agencies',
        link: '/pages/settings/port-agency/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.PORT_AGENCY,
        },
      },
      {
        title: 'Languages',
        link: '/pages/settings/language/' + LIST_COMPONENT_ROUTE,
        data: {
          permission: PERMISSION_LIST,
          resource: ModelTypes.LANGUAGE,
        },
      },
      {
        title: 'Dictionaries',
        icon: 'book-outline',
        data: {
          permission: 'manage',
          resource: 'dictionaries',
        },
        children: [


          {
            title: 'Regions',
            link: '/pages/settings/region/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.REGION,
            },
          },
          {
            title: 'Services',
            link: '/pages/settings/service/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.SERVICE,
            },
          },
          {
            title: 'Payment methods',
            link: '/pages/settings/payment-method/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.PAYMENT_METHOD,
            },
          },
          {
            title: 'Categories',
            link: '/pages/settings/transport-service/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.TRANSPORT_SERVICE,
            },
          },
          {
            title: 'Venue groups',
            link: '/pages/settings/venue-group/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.VENUE_GROUP,
            },
          },
          {
            title: 'Venue descriptions',
            link: '/pages/settings/venue-description/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.VENUE_DESCRIPTION,
            },
          },
          {
            title: 'Venue categories',
            link: '/pages/settings/venue-category/' + LIST_COMPONENT_ROUTE,
            data: {
              permission: PERMISSION_LIST,
              resource: ModelTypes.VENUE_CATEGORY,
            },
          },
        ],
      },

    ],
  },
  {
    title: 'Reports',
    icon: 'book-outline',
    link: '/pages/settings/report/' + LIST_COMPONENT_ROUTE,
    data: {
      permission: PERMISSION_LIST,
      resource: ModelTypes.REPORT,
    },
  },
];
