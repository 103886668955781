/* tslint:disable */
type GuideBookingStatus =
  'To_book' |
  'Request_Sent' |
  'Booked' |
  'Unavailable' |
  'Maybe' |
  'Allocated' |
  'Cancelled' |
  'Re_book';
module GuideBookingStatus {
  export const TO_BOOK: GuideBookingStatus = 'To_book';
  export const REQUEST_SENT: GuideBookingStatus = 'Request_Sent';
  export const BOOKED: GuideBookingStatus = 'Booked';
  export const UNAVAILABLE: GuideBookingStatus = 'Unavailable';
  export const MAYBE: GuideBookingStatus = 'Maybe';
  export const ALLOCATED: GuideBookingStatus = 'Allocated';
  export const CANCELLED: GuideBookingStatus = 'Cancelled';
  export const RE_BOOK: GuideBookingStatus = 'Re_book';
  export function values(): GuideBookingStatus[] {
    return [
      TO_BOOK,
      REQUEST_SENT,
      BOOKED,
      UNAVAILABLE,
      MAYBE,
      ALLOCATED,
      CANCELLED,
      RE_BOOK
    ];
  }
}

export { GuideBookingStatus }