/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StringApiResponse } from '../models/string-api-response';
import { RegisterTourGuestViewModel } from '../models/register-tour-guest-view-model';
import { AppUserViewModelApiResponse } from '../models/app-user-view-model-api-response';
import { GuideScheduleIEnumerableApiResponse } from '../models/guide-schedule-ienumerable-api-response';
import { DetailedTourTimingViewModelApiResponse } from '../models/detailed-tour-timing-view-model-api-response';
import { TourTimingViewModelApiResponse } from '../models/tour-timing-view-model-api-response';
import { BooleanApiResponse } from '../models/boolean-api-response';
import { DocumentViewModelIEnumerableApiResponse } from '../models/document-view-model-ienumerable-api-response';
import { TourUserLocationViewModelApiResponse } from '../models/tour-user-location-view-model-api-response';
import { TourUserLocationViewModel } from '../models/tour-user-location-view-model';
import { TourUserLocationViewModelIEnumerableApiResponse } from '../models/tour-user-location-view-model-ienumerable-api-response';
@Injectable({
  providedIn: 'root',
})
class ApiMobileService extends __BaseService {
  static readonly AuthenticateToTourPath = '/api/ApiMobile/AuthenticateToTour';
  static readonly GetMyAccountPath = '/api/ApiMobile/GetMyAccount';
  static readonly GetMySchedulePath = '/api/ApiMobile/GetMySchedule';
  static readonly GetTourPath = '/api/ApiMobile/GetTour';
  static readonly CompleteTourPath = '/api/ApiMobile/CompleteTour';
  static readonly UpdateFcmPath = '/api/ApiMobile/UpdateFcm';
  static readonly GetDocumentsPath = '/api/ApiMobile/GetDocuments';
  static readonly UpdateLocationPath = '/api/ApiMobile/UpdateLocation';
  static readonly GetGuideLocationPath = '/api/ApiMobile/GetGuideLocation';
  static readonly GetTourUsersLocationsPath = '/api/ApiMobile/GetTourUsersLocations';
  static readonly ContactGuidePath = '/api/ApiMobile/ContactGuide';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  AuthenticateToTourResponse(body?: RegisterTourGuestViewModel): __Observable<__StrictHttpResponse<StringApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ApiMobile/AuthenticateToTour`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StringApiResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  AuthenticateToTour(body?: RegisterTourGuestViewModel): __Observable<StringApiResponse> {
    return this.AuthenticateToTourResponse(body).pipe(
      __map(_r => _r.body as StringApiResponse)
    );
  }

  /**
   * @return Success
   */
  GetMyAccountResponse(): __Observable<__StrictHttpResponse<AppUserViewModelApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetMyAccount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppUserViewModelApiResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMyAccount(): __Observable<AppUserViewModelApiResponse> {
    return this.GetMyAccountResponse().pipe(
      __map(_r => _r.body as AppUserViewModelApiResponse)
    );
  }

  /**
   * @return Success
   */
  GetMyScheduleResponse(): __Observable<__StrictHttpResponse<GuideScheduleIEnumerableApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetMySchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GuideScheduleIEnumerableApiResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMySchedule(): __Observable<GuideScheduleIEnumerableApiResponse> {
    return this.GetMyScheduleResponse().pipe(
      __map(_r => _r.body as GuideScheduleIEnumerableApiResponse)
    );
  }

  /**
   * @param pin undefined
   * @return Success
   */
  GetTourResponse(pin?: string): __Observable<__StrictHttpResponse<DetailedTourTimingViewModelApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pin != null) __params = __params.set('pin', pin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetTour`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DetailedTourTimingViewModelApiResponse>;
      })
    );
  }
  /**
   * @param pin undefined
   * @return Success
   */
  GetTour(pin?: string): __Observable<DetailedTourTimingViewModelApiResponse> {
    return this.GetTourResponse(pin).pipe(
      __map(_r => _r.body as DetailedTourTimingViewModelApiResponse)
    );
  }

  /**
   * @param pin undefined
   * @return Success
   */
  CompleteTourResponse(pin?: string): __Observable<__StrictHttpResponse<TourTimingViewModelApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pin != null) __params = __params.set('pin', pin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/CompleteTour`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourTimingViewModelApiResponse>;
      })
    );
  }
  /**
   * @param pin undefined
   * @return Success
   */
  CompleteTour(pin?: string): __Observable<TourTimingViewModelApiResponse> {
    return this.CompleteTourResponse(pin).pipe(
      __map(_r => _r.body as TourTimingViewModelApiResponse)
    );
  }

  /**
   * @param fcm undefined
   * @return Success
   */
  UpdateFcmResponse(fcm?: string): __Observable<__StrictHttpResponse<BooleanApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (fcm != null) __params = __params.set('fcm', fcm.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/UpdateFcm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BooleanApiResponse>;
      })
    );
  }
  /**
   * @param fcm undefined
   * @return Success
   */
  UpdateFcm(fcm?: string): __Observable<BooleanApiResponse> {
    return this.UpdateFcmResponse(fcm).pipe(
      __map(_r => _r.body as BooleanApiResponse)
    );
  }

  /**
   * @param guid undefined
   * @return Success
   */
  GetDocumentsResponse(guid?: string): __Observable<__StrictHttpResponse<DocumentViewModelIEnumerableApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (guid != null) __params = __params.set('guid', guid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentViewModelIEnumerableApiResponse>;
      })
    );
  }
  /**
   * @param guid undefined
   * @return Success
   */
  GetDocuments(guid?: string): __Observable<DocumentViewModelIEnumerableApiResponse> {
    return this.GetDocumentsResponse(guid).pipe(
      __map(_r => _r.body as DocumentViewModelIEnumerableApiResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateLocationResponse(body?: TourUserLocationViewModel): __Observable<__StrictHttpResponse<TourUserLocationViewModelApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ApiMobile/UpdateLocation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourUserLocationViewModelApiResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateLocation(body?: TourUserLocationViewModel): __Observable<TourUserLocationViewModelApiResponse> {
    return this.UpdateLocationResponse(body).pipe(
      __map(_r => _r.body as TourUserLocationViewModelApiResponse)
    );
  }

  /**
   * @param pin undefined
   * @return Success
   */
  GetGuideLocationResponse(pin?: string): __Observable<__StrictHttpResponse<TourUserLocationViewModelApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pin != null) __params = __params.set('pin', pin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetGuideLocation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourUserLocationViewModelApiResponse>;
      })
    );
  }
  /**
   * @param pin undefined
   * @return Success
   */
  GetGuideLocation(pin?: string): __Observable<TourUserLocationViewModelApiResponse> {
    return this.GetGuideLocationResponse(pin).pipe(
      __map(_r => _r.body as TourUserLocationViewModelApiResponse)
    );
  }

  /**
   * @param pin undefined
   * @return Success
   */
  GetTourUsersLocationsResponse(pin?: string): __Observable<__StrictHttpResponse<TourUserLocationViewModelIEnumerableApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pin != null) __params = __params.set('pin', pin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/GetTourUsersLocations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourUserLocationViewModelIEnumerableApiResponse>;
      })
    );
  }
  /**
   * @param pin undefined
   * @return Success
   */
  GetTourUsersLocations(pin?: string): __Observable<TourUserLocationViewModelIEnumerableApiResponse> {
    return this.GetTourUsersLocationsResponse(pin).pipe(
      __map(_r => _r.body as TourUserLocationViewModelIEnumerableApiResponse)
    );
  }

  /**
   * @param pin undefined
   * @return Success
   */
  ContactGuideResponse(pin?: string): __Observable<__StrictHttpResponse<BooleanApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pin != null) __params = __params.set('pin', pin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApiMobile/ContactGuide`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BooleanApiResponse>;
      })
    );
  }
  /**
   * @param pin undefined
   * @return Success
   */
  ContactGuide(pin?: string): __Observable<BooleanApiResponse> {
    return this.ContactGuideResponse(pin).pipe(
      __map(_r => _r.body as BooleanApiResponse)
    );
  }
}

module ApiMobileService {
}

export { ApiMobileService }
