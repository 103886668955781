/* tslint:disable */
type GuideQualification =
  'BlueBadge' |
  'GreenBadge' |
  'YellowBadge' |
  'NotQualifified' |
  'RegionalQualification';
module GuideQualification {
  export const BLUE_BADGE: GuideQualification = 'BlueBadge';
  export const GREEN_BADGE: GuideQualification = 'GreenBadge';
  export const YELLOW_BADGE: GuideQualification = 'YellowBadge';
  export const NOT_QUALIFIFIED: GuideQualification = 'NotQualifified';
  export const REGIONAL_QUALIFICATION: GuideQualification = 'RegionalQualification';
  export function values(): GuideQualification[] {
    return [
      BLUE_BADGE,
      GREEN_BADGE,
      YELLOW_BADGE,
      NOT_QUALIFIFIED,
      REGIONAL_QUALIFICATION
    ];
  }
}

export { GuideQualification }