import {Component} from '@angular/core';
import {ApiCrudService, BaseDynamicFormComponent} from 'defdev-angular-nebular';
import {AppUserViewModel, ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent extends BaseDynamicFormComponent<AppUserViewModel> {


  protected getService(): ApiCrudService<AppUserViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.USER);
  }

}
