import {Component} from '@angular/core';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-venues',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class VenuesComponent {
  tableConfig: ITableConfig = {
    modelType: ModelTypes.VENUE,
    editModes: ['PAGE'],
    title: 'Venues & Suppliers',
  };


  constructor() {
  }


}
