import {Component, OnInit} from '@angular/core';
import {containsAny, ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';
import {ApiBidsService} from '../../../generated-api/services';
import {NbRoleProvider} from '@nebular/security';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ngx-bids-list',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig" ' +
    '(custom)="customAction($event)"></ngx-config-based-table-component>',
  styles: [],
})
export class BidsListComponent implements OnInit {

  tableConfig: ITableConfig = {
    title: 'Calendar entries',
    modelType: ModelTypes.BID,
    editModes: ['PAGE'],
    extraToolbarActionsInOppositeDirection: [],
    defaultFiltersFunction: () => [{
      type: 'boolean',
      operator: '=',
      field: 'bidInvoiced',
      value: 'false',
    }//,
     // {
     //   type: 'BidStatus',
     //   operator: 'not_in',
     //   field: 'status',
     //   value: ['Completed', 'Cancelled'],
     // },
    ],
  };

  constructor(private bidService: ApiBidsService, readonly roleProvider: NbRoleProvider) {
  }

  protected initTable() {
    const userActions = [];
    this.roleProvider.getRole()
      .pipe(
        map((role: string | string[]) => Array.isArray(role) ? role : [role]))
      .subscribe(roles => {
        this.tableConfig = {
          title: 'Calendar entries',
          modelType: ModelTypes.BID,
          editModes: ['PAGE'],
          extraToolbarActionsInOppositeDirection: userActions,
          defaultFiltersFunction: () => [{
            type: 'boolean',
            operator: '=',
            field: 'bidInvoiced',
            value: 'false',
          },
            {
              type: 'BidStatus',
              operator: 'not_in',
              field: 'status',
              value: ['Cancelled'],
            },
          ],
        };
      });
  }

  customAction($event: any) {
    if ($event.action === 'po-report') {
      // PO number, Port, Ship, dates, coordinator.

    }

  }

  ngOnInit(): void {
    this.initTable();
  }
}
