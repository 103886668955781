import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {AppUserViewModel} from '../../../generated-api/models';
import {ApiUsersService} from '../../../generated-api/services';
import {NbTokenService} from '@nebular/auth';

import {Router} from '@angular/router';
import {DialogService, extractContent, FormModel} from 'defdev-angular-nebular';

@Component({
  selector: 'ngx-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {


  formModel: FormModel<AppUserViewModel>;


  constructor(

    private readonly tokenService: NbTokenService,
    private readonly usersService: ApiUsersService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.loadAccount();
  }

  public save(): void {
    if (this.formModel.object.id > 0) {
      this.usersService.Put(this.formModel.object).pipe(extractContent()).subscribe(x => this.afterSuccess(x));
    } else {
      this.usersService.Post(this.formModel.object).pipe(extractContent()).subscribe(x => this.afterSuccess(x));
    }

  }

  onCancel() {
    this.goToLastPageOrDefault();
  }

  onChangePassword() {
    this.dialogService.showInputEditorDialog({
      header: 'Change password',
      label: 'Password',
      type: 'input',
      input: '',
    }).onConfirm((model) => {
      this.usersService.ChangePassword({password: model.input}).subscribe(() => {
      });
    });
  }

  private loadAccount() {
    this.tokenService.get().subscribe(token => {
      this.usersService.GetWithEditData({id: Number(token.getPayload()['sub'])}).pipe(map(d => d.content))
        .subscribe(formModel => {
          this.formModel = formModel;
        });

    });

  }

  private afterSuccess(x: AppUserViewModel) {
    this.goToLastPageOrDefault();
  }

  private goToLastPageOrDefault() {
    this.router.navigate(['/']);
  }
}
