import { Component, OnInit } from '@angular/core';
import { containsAny, DialogService, extractContent, ITableConfig } from 'defdev-angular-nebular';
import { ModelTypes } from '../../../generated-api/models';
import { BaseUsersComponent } from './base-users.component';
import { map } from 'rxjs/operators';
import { ApiAuthenticationService, ApiUsersService } from '../../../generated-api/services';
import { NbRoleProvider } from '@nebular/security';

@Component({
  selector: 'ngx-users-settings',
  template: `
    <ngx-config-based-table-component *ngIf="tableConfig"
                                      [tableConfig]="tableConfig"
                                      (custom)="onCustomAction($event)"></ngx-config-based-table-component>`,
})
export class PortCoordinatorsComponent implements OnInit {

  constructor(
    readonly service: ApiUsersService,
    readonly authService: ApiAuthenticationService,
    readonly dialogService: DialogService,
    readonly roleProvider: NbRoleProvider) {
  }
  tableConfig: ITableConfig;

  protected getTableConfig(isEdit: boolean, isDelete: boolean, additionalUserActions: { name: string; title: string }[]): ITableConfig {
    return {
      title: 'Port coordinators',
      modelType: ModelTypes.USER,
      // actions for elements in row
      customTableActions: additionalUserActions,
      showBulkUpdate: false,
      // if ca be edited inline
      editModes: ['MODAL', 'INLINE'],
      referencedProperties: [
        {
          type: 'string',
          field: 'RolesString',
          value: 'LocalPortCoordinator',
          operator: 'like',
        },
      ],
      defaultFiltersFunction: () => [
        {
          type: 'string',
          operator: 'like',
          field: 'RolesString',
          value: 'LocalPortCoordinator',
        },
      ],
      showActionsToolbar: isEdit,
      editButtonEnabled: isEdit,
      addButtonEnabled: isEdit,
      deleteButtonEnabled: isEdit
      // TODO
      // queryParamsFunction: ()=> {'RolesString': 'PortCoordinator'},
    };
  }

  ngOnInit(): void {
    this.initTable();
  }

  protected onCustomAction(event) {
    if (event.action === 'password') {
      this.dialogService.showPasswordDialog({}).onConfirm((context) => {
        this.service.ChangePassword({ password: context.input, userId: event.data.id }).subscribe(() => {
        });
      });
    }
  }

  protected initTable() {
    const userActions = [];
    var isEdit: boolean = false;
    var isDelete: boolean = false;

    this.roleProvider.getRole()
      .pipe(
        map((role: string | string[]) => Array.isArray(role) ? role : [role]))
      .subscribe(roles => {
        var editmode = [];
        if (this.hasChangePassword(roles)) {
          userActions.push({ name: 'password', title: '<i class="fas fa-lock" title="Change password"></i>' });
        }
        if (this.hasEdit(roles)) {
          isEdit = true;
        }
        if (this.hasDelete(roles)) {
          isDelete = true;
        }
        this.tableConfig = this.getTableConfig(isEdit, isDelete, userActions);
      });
  }

  protected hasChangePassword(roles: string[]) {
    return true;
  }
  protected hasEdit(roles: string[]) {
    return containsAny(roles, ['Admin']);
  }
  protected hasDelete(roles: string[]) {
    return containsAny(roles, ['SuperAdmin']);
  }
}
