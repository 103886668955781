import { Component, Inject, ViewChild, OnInit} from '@angular/core';
import {ITableConfig, ConfigBasedTableComponent} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';
import {
  ApiTourTimingsService
} from '../../../generated-api/services';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../utils.service';

@Component({
  selector: 'ngx-tour-timing-list',
  templateUrl: './tour-timing-list.component.html',
})
export class TourTimingListComponent {
  cloning: boolean = false;
  selectedIds: number[];
  CloneToTableRef: ConfigBasedTableComponent;
  TimingTableRef: ConfigBasedTableComponent;
  message: string = "";
  OpsDocumentsTableConfig: ITableConfig = {
    modelType: ModelTypes.OPS_DOCUMENT,
    title: null,
    showBulkUpdate: false,
    editModes: [],
    editButtonEnabled: false,
    deleteButtonEnabled: false,
    addButtonEnabled: false,
    selectionMode: "multi",
    exportableColumns: [],
    hiddenColumns: ["toursCount", "actionsStatus", "invoiced"],
    defaultFiltersFunction: () => [
      {
        type: 'boolean',
        operator: '=',
        field: 'invoiced',
        value: 'false',
      },
      {
        type: 'boolean',
        operator: '=',
        field: 'cancelled',
        value: 'false',
      },
    ],
  };
  private eventsSubscription: Subscription;

  tableConfig: ITableConfig = {
    modelType: ModelTypes.TOUR_TIMING,
    title: 'Tour timings',
    extraToolbarActions: [{ icon: "copy-outline", name: "Clone", title: "Clone" }],
    addButtonEnabled: false
  };

  constructor(private readonly tourTimingService: ApiTourTimingsService, private util: UtilityService) {
  }


  @ViewChild(ConfigBasedTableComponent, { static: false })
  set CloneToTable(val) {
    this.CloneToTableRef = val;
  };

  @ViewChild(ConfigBasedTableComponent, { static: false })
  set TimingTable(val) {
    this.TimingTableRef = val;
  };


  ngOnInit() {
    this.eventsSubscription = this.util.eventsSubject.subscribe((d) => {
      if (d == "TourTimingListComponent") this.TimingTable.refresh();
    });
  }


  protected onCustomAction(event) {
    if (event.action == "Clone") {

      var selectedRows = this.TimingTableRef.tableComponent.table.selectedRows;
      this.selectedIds = [];

      for (var i = 0; i < selectedRows.length; i++)
        this.selectedIds.push(selectedRows[i].id);

      if (this.selectedIds.length > 0) this.cloning = true;
    }
  }

  public cloneIntoOpsDocuments() {
    var selectedOpsDocuments = this.CloneToTableRef.tableComponent.table.selectedRows;
    var selectedOpsIds: number[] = [];

    for (var i = 0; i < selectedOpsDocuments.length; i++)
      selectedOpsIds.push(selectedOpsDocuments[i].id);

    this.tourTimingService.CloneMulti({
      opDocumentIds: selectedOpsIds,
      tourIds: this.selectedIds
    }).subscribe(x => {
      this.message = "Clone completed successfully";
    });

    this.cloning = false;
  }

}
