import {APP_BASE_HREF} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';
import {ApiConfiguration} from './generated-api/api-configuration';
import {LogoutInterceptor} from './auth/logout/logout.interceptor';
import {AuthGuard} from './auth/auth-guard';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {AgmCoreModule} from '@agm/core';
import {ApiConfigService, ApiDocumentsService, ApiNotesService, ApiUsersService} from './generated-api/services';
import {
  AuthInterceptor,
  CONFIG_CRUD_SERVICE,
  DefdevAngularNebularModule,
  DOCUMENTS_CRUD_SERVICE,
  LOGGED_USER_SERVICE,
  MODEL_MAPPER_SERVICE,
  NOTES_CRUD_SERVICE,
} from 'defdev-angular-nebular';
import {AppModelMapperService} from './app-model-mapper.service';
import {ModelTypes} from './generated-api/models';
import {PagesModule} from "./pages/pages.module";
import { UtilityService } from './utils.service';


export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    // i ahve to commetn it before release
    // because i cant build it in production mode becaouse of errors according to my lib i will fix it later
    // if (!environment.production) {
    //   config.rootUrl = 'http://localhost:4222';
    // }
  };
}


export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true,
};

function generateCorrectPermissions(values: Array<string>) {
  return [...values.map(x => x), ...values];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDqMUiEVK8oBXwG1TWCB4hcRfXdKfwMgig',
    }),
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/AppLogs',
      level: NgxLoggerLevel.DEBUG,
      // serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: environment.production,
    }),
    DefdevAngularNebularModule.forRoot({
      securityName: environment.securityStrategyName,
      nbSecurityOptions: {
        accessControl: {
          Guest: {
            list: [ModelTypes.NOTE, ModelTypes.DOCUMENT],
            create: [
              ModelTypes.NOTE, ModelTypes.DOCUMENT,
              ModelTypes.NOTE, ModelTypes.DOCUMENT,
            ],
            edit: [ModelTypes.NOTE, ModelTypes.DOCUMENT],
            remove: [ModelTypes.NOTE, ModelTypes.DOCUMENT],
          },
          Guide: {
            parent: 'Guest',
          },
          Viewer: {
            parent: 'Guide',
            calendar: '*',
          },
          Admin: {
            parent: 'Viewer',
            create: [
                ModelTypes.USER, ModelTypes.BID, ModelTypes.OPS_DOCUMENT, ModelTypes.GUIDE,
                ModelTypes.LANGUAGE, ModelTypes.SHIP, ModelTypes.PORT, ModelTypes.VENUE,
                ModelTypes.TRANSPORT, ModelTypes.TRANSPORT_CONTACT, ModelTypes.VENUE_CONTACT,
                ModelTypes.PORT_AGENCY, ModelTypes.PORT_AGENT, ModelTypes.TOUR,
                ModelTypes.TOUR_TIMING, ModelTypes.VENUE_BOOKING, ModelTypes.GUIDE_BOOKING,
                ModelTypes.TRANSPORT_BOOKING, ModelTypes.OPS_DOCUMENT_ACTION, ModelTypes.SHIP_ITINERY,
                ModelTypes.CLIENT,
              ],
            edit: [
              ModelTypes.BID, ModelTypes.OPS_DOCUMENT, ModelTypes.GUIDE,
              ModelTypes.LANGUAGE, ModelTypes.SHIP, ModelTypes.PORT, ModelTypes.VENUE,
              ModelTypes.TRANSPORT, ModelTypes.TRANSPORT_CONTACT, ModelTypes.VENUE_CONTACT,
              ModelTypes.PORT_AGENCY, ModelTypes.PORT_AGENT, ModelTypes.USER, ModelTypes.TOUR,
              ModelTypes.TOUR_TIMING, ModelTypes.VENUE_BOOKING, ModelTypes.GUIDE_BOOKING,
              ModelTypes.TRANSPORT_BOOKING, ModelTypes.OPS_DOCUMENT_ACTION, ModelTypes.SHIP_ITINERY,
            ],
            remove: [ModelTypes.TOUR, ModelTypes.TOUR_TIMING],
            list: [
              ModelTypes.BID, ModelTypes.OPS_DOCUMENT, ModelTypes.GUIDE,
              ModelTypes.LANGUAGE, ModelTypes.SHIP, ModelTypes.PORT,
              ModelTypes.VENUE, ModelTypes.TRANSPORT, ModelTypes.TRANSPORT_CONTACT,
              ModelTypes.VENUE_CONTACT, ModelTypes.PORT_AGENCY, ModelTypes.PORT_AGENT, ModelTypes.USER, ModelTypes.TOUR,
              ModelTypes.TOUR_TIMING, ModelTypes.VENUE_BOOKING, ModelTypes.GUIDE_BOOKING,
              ModelTypes.TRANSPORT_BOOKING, ModelTypes.OPS_DOCUMENT_ACTION, ModelTypes.SHIP_ITINERY, ModelTypes.CLIENT,
            ],
            manage: 'setup',
          },
          SuperAdmin: {
            parent: 'Admin',
            create: '*',
            edit: '*',
            remove: '*',
            list: '*',
            manage: '*',
          },
        },
      },
    }),
    PagesModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NOTES_CRUD_SERVICE,
      useClass: ApiNotesService,
      multi: false,
    },
    {
      provide: DOCUMENTS_CRUD_SERVICE,
      useClass: ApiDocumentsService,
      multi: false,
    },
    {
      provide: CONFIG_CRUD_SERVICE,
      useClass: ApiConfigService,
      multi: false,
    },
    {
      provide: LOGGED_USER_SERVICE,
      useClass: ApiUsersService,
      multi: false,
    },
    {
      provide: MODEL_MAPPER_SERVICE,
      useClass: AppModelMapperService,
      multi: false,
    },
    AuthGuard,
    INIT_API_CONFIGURATION,
    UtilityService
  ]
})
export class AppModule {
}
