import {Component, OnInit, ViewChild} from '@angular/core';
import {BidViewModel} from '../../../generated-api/models/bid-view-model';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {flatMap} from 'rxjs/operators';
import {ApiBidsService, ApiConfigService, ApiOpsDocumentService} from '../../../generated-api/services';
import {
  DialogService,
  DynamicFormComponent,
  extractContent,
  FieldChangedModel,
  FormModel,
  getDefaultId,
  RouterHelperService,
  ITableConfig
} from 'defdev-angular-nebular';
import {BidStatus, FormMode, ModelTypes} from '../../../generated-api/models';
import {AppModelMapperService} from '../../../app-model-mapper.service';
import { NbAccessChecker } from "@nebular/security";


@Component({
  selector: 'ngx-bid-details',
  templateUrl: './bid-details.component.html',
  styles: [],
})
export class BidDetailsComponent implements OnInit {
  formModel: FormModel<BidViewModel>;

  @ViewChild(DynamicFormComponent, {static: false})
  ngxForm: DynamicFormComponent<BidViewModel>;


  showWarningMessage = false;
  showWarningMessageForField = ['portid', 'clientid', 'shipid'];

  showDateChangedAlert = false;

  constructor(private readonly router: Router,
              private readonly location: Location,
              private readonly service: ApiBidsService,
              private readonly routeService: RouterHelperService,
              private readonly opsDocumentService: ApiOpsDocumentService,
              private readonly dialogService: DialogService,
              private readonly configService: ApiConfigService,
              private readonly appModelMapper: AppModelMapperService,
              private readonly accessChecker: NbAccessChecker) {
  }

  ngOnInit() {
    this.routeService.getParamFromRoute('id').pipe(
      flatMap(id => this.service.GetWithEditData({
        id: getDefaultId(id),
      })))
      .pipe(extractContent())
      .subscribe(formModel => {
        if (this.isBidCompleted(formModel.object)) {
          formModel.formConfig.formMode = FormMode.VIEW;
        }
        this.formModel = formModel;

        //  Update the tab title
        let tab = document.querySelector(".nav-tabs .nav-link.active .flex-fill");
        let clientname = "";
        let clients = this.formModel.formConfig.formGroups[1].editFields[2].options;
        if (this.formModel.object.clientId != null) {
          for (var j = 0; j < clients.length; j++) {
            if (clients[j].optionId.toString() === this.formModel.object.clientId.toString()) {
              clientname = clients[j].optionText;
              break;
            }
          }
        }
        let d = new Date(Date.parse(this.formModel.object.startDate));
        if (this.pad(d.getDate()) == NaN) {
          tab.innerHTML = "New Bid";
        } else {
          tab.innerHTML = clientname.substring(0, 3) + " " + this.pad(d.getDate()) + "/" + this.pad(d.getMonth() + 1);
        }
      });
  }

  public pad(num: Number) {
    if (num.toString().length == 1) return "0" + num.toString();
    return num;
  }
  public save(closeCurrentPage: boolean = true): void {
    if (this.formModel.object.id > 0) {
      if (this.showDateChangedAlert) {
        this.dialogService.showConfirmationDialog(
          {
            header: 'Do you want to update the date?',
            message: 'Updating the date will cancel the current calendar entry, ops documents and trips. ' +
              'The ops will be duplicated and all service reconfirmed. Are you sure you want to continue ?',
          },
        ).onConfirm(() => {
          this.service.Put(this.formModel.object)
            .pipe(extractContent()).subscribe(x => this.afterSuccess(x, closeCurrentPage));
        });
      } else {
        console.log(this.formModel)
        this.service.Put(this.formModel.object).pipe(extractContent()).subscribe(x => this.afterSuccess(x, closeCurrentPage));
      }

    } else {
      if (!this.isBidCompleted(this.formModel.object)) {
        this.formModel.object.status = this.getBidStatus(this.formModel.object);
      }

      this.service.Post(this.formModel.object).pipe(extractContent())
        .subscribe(x => this.afterSuccess(x, closeCurrentPage));
    }

    const po: HTMLInputElement = document.querySelector(".tab-pane.active #poNumber");
    if (po != null && this.formModel.object.poNumber != null) {
      po.setAttribute("disabled", "disabled");
    }

    const po2: HTMLInputElement = document.querySelector(".tab-pane.active #poNumber2");
    if (po2 != null && this.formModel.object.poNumber2 != null) {
      po2.setAttribute("disabled", "disabled");
    }

  }

  isBidCompleted(bid: BidViewModel): boolean {
    return bid.status === BidStatus.CANCELLED || bid.status === BidStatus.COMPLETED;
  }


  onFormValueChanged(event: FieldChangedModel) {
    if (this.formModel.object.id > 0) {
      if (this.showWarningMessageForField.indexOf(event.name.toLowerCase()) > -1) {
        this.showWarningMessage = true;
      }

      // excursions date
      if (event.name.toLowerCase() === 'startdate') {
        this.showDateChangedAlert = true;
      }
    }

    if (event.name.toLowerCase() === 'startdate' && event.newVal !== '') {
      //  Update arrival date
      const s = event.newVal.split('/');
      const newDate = s[2] + '-' + s[1] + '-' + s[0];
      this.ngxForm.setValue('arrivalDate', newDate);

      //  Update the other dates so long as they are empty
      if (this.formModel.object.endDate == null) this.ngxForm.setValue('endDate', newDate);
      if (this.formModel.object.departureDate == null) this.ngxForm.setValue('departureDate', newDate);
    }

    if (event.name.toLowerCase() === 'enddate' && event.newVal !== '') {
      //  Update the other end dates
      let s = event.newVal.split('/');
      let newDate = s[2] + '-' + s[1] + '-' + s[0];
      this.ngxForm.setValue('endDate', newDate);
      this.ngxForm.setValue('departureDate', newDate);
    }

    this.setupPONumber(this);
  }

  ngAfterContentChecked() {
    this.setupPONumber(this);
  }


  setupPONumber(t) {
    const originalbutton = document.querySelector(".tab-pane.active .po-generation-btn");

    let po: HTMLInputElement = document.querySelector(".tab-pane.active #poNumber");
    if (po != null) {

      if (t.formModel != null && t.formModel.object.poNumber !== null && t.formModel.object.poNumber !== undefined && t.formModel.object.poNumber !== "") {
        if (originalbutton != null) originalbutton.parentNode.removeChild(originalbutton);
        po.value = t.formModel.object.poNumber;
        po.setAttribute("disabled", "disabled");
        console.log("Setting to disabled");
      } else {
        if (originalbutton == null) {
          console.log("adding generate button");
          //  Add a button to generate the PO
          let button = document.createElement("button");
          let div = document.createElement("div");
          div.classList.add("po-generation-btn")
          let parent = po.parentNode;
          button.classList.add("btn");
          button.classList.add("btn-xs");
          button.classList.add("btn-outline-secondary");
          button.type = "button";
          button.innerHTML = "Generate";
          button.addEventListener("click", this.getPoNumber.bind(this));// function () {
          div.classList.add("input-group-append");
          div.appendChild(button);
          parent.appendChild(div);
          console.log(parent);
          po.classList.remove("input-full-width");
        }
      }
    }

    const po2: HTMLInputElement = document.querySelector(".tab-pane.active #poNumber2");
    if (po2 != null)
      if (this.formModel != null)
        if (this.formModel.object.poNumber2 != null && this.formModel.object.poNumber2 != undefined)
          if (this.formModel.object.poNumber2 != "")
            po2.setAttribute("disabled", "disabled");
    
  }

  getPoNumber() {
    var me = this;
    this.service.GetNextPONumber(this.formModel.object.id).subscribe(x => {
      var t = me.formModel;
      t.object.poNumber = x.content;
      me.formModel = t;
    });
  }

  moveToOpsDocument() {
    if (this.formModel.object.clientId == null) {
      var clientid: HTMLSelectElement = document.querySelector("#clientId");
      clientid.focus();
      alert("Client is required");
      return null;
    }

    if (this.formModel.object.shipId == null) {
      var shipid: HTMLSelectElement = document.querySelector("#shipId");
      shipid.focus();
      alert("Ship is required");
      return null;
    }


    this.service.AddOrUpdate(this.formModel.object).pipe(extractContent()).subscribe(x => {
      this.opsDocumentService.CreateOpsDocument(this.formModel.object).pipe(extractContent()).subscribe(id => {
        this.router.navigate(['pages/operation/ops-document/detail/' + id]);
      });
    });
  }

  cancelBid() {
    this.configService.GetEditFormModel({id: '', modelType: ModelTypes.BID_CANCELLATION})
      .pipe(extractContent())
      .subscribe(formModel => {
        this.dialogService.showDynamicEditorDialog({formModel, header: 'Do you want to cancel'})
          .onConfirm((result) => {
            this.formModel.object.cancellation = result.formModel.object;
            this.formModel.object.isCancelled = true;
            this.showDateChangedAlert = false;
            this.save();
          });
      });
  }

  private onCancel() {
    this.goToLastPageOrDefault();
  }

  protected afterSuccess(x: BidViewModel, closeCurrentPage: boolean = true) {
    if (closeCurrentPage) {
      this.goToLastPageOrDefault();
    } else {
      if (this.formModel.formConfig.formMode !== FormMode.EDIT) {
        this.appModelMapper.navigateToDetails(this.formModel.formConfig.modelType, x.id, []).then(() => {
          // window.location.reload();
        });
      }

    }

  }

  private goToLastPageOrDefault() {
    this.location.back();
  }

  private getBidStatus(bid: BidViewModel) {
    if (bid.bidReqDate) {
      return BidStatus.REQUESTED;
    }
    if (bid.bidSentDate) {
      return BidStatus.SENT;
    }
    if (bid.bidConfDate) {
      return BidStatus.APPOINTED;
    }
    return null;
  }
}
