import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NbIconLibraries, NbMenuItem, NbSearchService } from '@nebular/theme';
import { Subject } from 'rxjs';
import {
  ConfigService,
  DialogService,
  HttpLoaderService,
  MODEL_MAPPER_SERVICE,
  ModelMapperService, RouterHelperService, LoggedUserService
} from 'defdev-angular-nebular';
import {MENU_ITEMS} from "./pages/pages-menu";
import {environment} from "../environments/environment";
import {NbAccessChecker} from "@nebular/security";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import { NbAuthService } from "@nebular/auth";
import { UtilityService } from '../app/utils.service';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationStart,
  Route,
  Router,
} from "@angular/router";


@Component({
  selector: 'ngx-app',
  styleUrls: ['./app.component.scss'],
  template: `
    <ng-container *ngIf="initialized && !(authService.isAuthenticated() | async)">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngIf="initialized && (authService.isAuthenticated() | async)">
      <nb-layout windowMode>
        <nb-layout-header fixed>
          <ngx-header [userMenu]="userMenu" [title]="appTitle">
            <ng-container actions>
              <nb-actions>
                <nb-action size="large">
                  <nb-search type="rotate-layout" size="large" title="Search for items"
                             hint="Hit enter to search."></nb-search>

                </nb-action>
              </nb-actions>
            </ng-container>
          </ngx-header>
        </nb-layout-header>

        <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
          <nb-menu *ngIf='menu != null' [items]="menu"></nb-menu>
        </nb-sidebar>

        <nb-layout-column [nbSpinner]="loaderService.isLoading$ | async">

          <nb-card>
            <nb-card-body>
              <!-- All tabs -->
              <ul class="nav nav-tabs">
                <li class="nav-item" *ngFor="let tab of tabs; let first = first;"
                >
                  <div class="nav-link" [class.active]="tab.active">
                    <div class="d-flex flex-row cursor-pointer">
                      <div class="flex-fill" (click)="navigateToTab(tab)">{{ tab.name }}</div>
                      <span *ngIf="tabs.length > 1" style="margin-left:5px;"><i class="fas fa-window-close"
                                                                                title="Delete"
                                                                                (click)="disposeTab(tab)"></i></span>
                    </div>
                  </div>
                </li>
              </ul>
            </nb-card-body>
          </nb-card>
          <div class="tab-content flex-fill">
            <ng-container *ngFor="let tab of tabs">
              <div class="tab-pane fade {{tab.name}}" [class.show]="tab.active" [class.active]="tab.active"
                   [id]="tab.name" role="tabpanel" [hidden]="!tab.active"   >
                <!--  -->
                <ng-container *ngComponentOutlet="tab.component;"></ng-container>

              </div>
            </ng-container>
          </div>

        </nb-layout-column>

        <nb-layout-footer fixed>
          <ngx-footer></ngx-footer>
        </nb-layout-footer>
      </nb-layout>
    </ng-container>

  `,
})
export class AppComponent implements OnInit {
  public tabs: Tab[] = [];
  public routes: Route[] = [];
  public currentHoverTabKey: string;

  menu = MENU_ITEMS;
  appTitle = environment.appName;
  userMenu = [
    {
      title: 'My account',
      link: '/pages/settings/account',
    },

    {
      title: 'Log out',
      link: '/auth/logout',
    },
  ];
  initialized: boolean;


  componentNameToVerbose = {
    'UsersSettings': 'Staff List',
    'BidsCalendar': 'Calendar',
    'BidsList': 'List',
    'TourMap': 'Tour map',
    "OpsDocuments": "Ops Document",
    "OpsDocumentDetails": "Ops Document details",
    "TourTiming": "Tour Timing",
    "TourTimingDetails": "Tour Booking",
    "TourDetails": "Tour Booking",
    "Venue_booking": "Venue Booking",
    "Venue_bookingDetails": "Venue Booking details",
    "TransportServices": "Categories",
    "Transport_booking": "Transport Booking",
    "Guide_booking": "Guide Booking"
  };

  getCompName(compName: string, model_type: string, id: string): string {
    if (compName === "NbLogoutComponent") {
      this.authService.logout(environment.securityStrategyName).subscribe(x => {
        window.location.assign("/");
      });
      return;
    }

    let name = compName.replace('Component', '');
    if (this.componentNameToVerbose[name]) {
      name = this.componentNameToVerbose[name];
    } else {
      if (name.indexOf('ModelBased') > -1) {
        name = model_type;
        name = name[0].toUpperCase() + name.slice(1);
        if (this.componentNameToVerbose[name]) name = this.componentNameToVerbose[name];
        if (id) {
        } else {
          name += 's';
        }
      } else {
        if (name.indexOf('Details') > -1) {
          name = name.replace('Details', '') + ' details';
        } else if (name.indexOf('List') > -1) {
          name = name.replace('List', '') + 's';
        }
      }
    }
    name = name.replace('ss', 's');

    if (id != undefined) name += ":" + id;

    return name;
  }


  constructor(
    private readonly authService: NbAuthService,
    private readonly userService: LoggedUserService,
    private configService: ConfigService,
    private iconLibraries: NbIconLibraries,
    public accessChecker: NbAccessChecker, public loaderService: HttpLoaderService,
    private searchService: NbSearchService,
    private dialogService: DialogService,
    @Inject(MODEL_MAPPER_SERVICE) private crudMapperService: ModelMapperService,
    private router: Router,
    private routerHelper: RouterHelperService,
    private util: UtilityService,
    private cd: ChangeDetectorRef) {
      router.events.pipe(distinctUntilChanged()).subscribe(val => {
        if (val instanceof NavigationStart && val.navigationTrigger === 'popstate' && val.url.indexOf('tab') === -1) {

          console.log(this.tabs);

          if (this.tabs.length > 5) {
            alert("You may find the speed of the site deteriorates if you add any more tabs.");
          }

          const activeTab = this.tabs.filter(x => x.active);
          if (activeTab.length > 0) {
            this.disposeTab(activeTab[0]);
          }
        } else if (val instanceof ActivationEnd) {
          this.checkAndAddRouteTab(val);
        }
    });
    this.checkAuthenticated();
  }

  checkAuthenticated() {
    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.initSearchEngine();
        this.userService.getLoggedUser().subscribe(user => {
            this.authMenuItems();
        })
      } else {
        this.checkAuthenticated();
      }
    });
  }

  ngOnInit() {
    this.configService.ngOnInit();
    this.iconLibraries.registerFontPack('nebular', {iconClassPrefix: 'nb'});
    this.iconLibraries.setDefaultPack('nebular');
    setTimeout(() => {
      this.initialized = true;
    }, 100);
  }


  authMenuItems() {
    this.menu.forEach(item => {
      this.authMenuItem(item);
    });
  }

  authMenuItem(menuItem: NbMenuItem) {

    if (menuItem.data && menuItem.data['permission'] && menuItem.data['resource']) {
      this.accessChecker.isGranted(menuItem.data['permission'], menuItem.data['resource']).subscribe(granted => {
        menuItem.hidden = !granted;
      });
    } else {
      menuItem.hidden = false;
    }
    if (!menuItem.hidden && menuItem.children != null) {
      menuItem.children.forEach(item => {
        if (item.data && item.data['permission'] && item.data['resource']) {
          this.accessChecker.isGranted(item.data['permission'], item.data['resource']).subscribe(granted => {
            item.hidden = !granted;
          });
        } else {
          // if child item do not config-list any `data.permission` and `data.resource` just inherit parent item's config-list
          item.hidden = menuItem.hidden;
        }
      });
    }
  }

  private initSearchEngine() {
    this.searchService.onSearchSubmit()
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((data: any) => {
        const term = data.term;
        const allServices = this.crudMapperService.getAllCrudServices();
        const searchQuery = Object.keys(allServices)
          .map((model) =>
            allServices[model].Search(term).pipe(
              map(x => x.content),
              map(x => {
                return {type: model, result: x};
              }),
            ));

        forkJoin(searchQuery)
          .subscribe(searchResult => {
            const list = [];
            searchResult.forEach(x => {
              x.result.forEach(val => {
                val.optionAdditionalInfo['model'] = x.type;
                list.push(val);
              });
            });


            this.dialogService.showSelectListEditorDialog({
              header: 'Search results',
              okButtonText: 'View',
              items: list,
              selected: '',
              multiple: false,
              namePropertyName: 'model',
            })
              .onConfirm(dialogContent => {
                const selected = list.find(x => x.optionId === dialogContent.selected);
                this.crudMapperService.navigateToDetails(selected.optionAdditionalInfo['model'],
                  dialogContent.selected,
                  {});
              });
          });
      });
  }

  disposeTab(tab: Tab) {
    if (this.tabs.length > 1) {
      this.tabs = this.tabs.filter(item => item.url !== tab.url);

      if (tab.active) {
        // deactivate all tabs
        this.deactivateTabs();
        var nextTab = this.tabs[this.tabs.length - 1];
        this.router.navigateByUrl(nextTab.url);

        nextTab.active = true;
        this.util.eventsSubject.next(nextTab.key);
        this.cd.markForCheck();
      }
    }
  }

  mouseOverTab(tab: Tab) {
    this.currentHoverTabKey = tab ? tab.url : null;
  }

  showTab(tab) {
    this.deactivateTabs();
    tab.active = true;
    this.cd.markForCheck();
  }

  checkAndAddRouteTab(val: ActivationEnd) {
    // get the component to activate by the route

    let comp = val.snapshot.component;
    if (comp == null && val.snapshot.root.firstChild.firstChild.firstChild) {
      comp = val.snapshot.root.firstChild.firstChild.firstChild.component;
    }
    if (comp == null && val.snapshot.root.firstChild.firstChild) {
      comp = val.snapshot.root.firstChild.firstChild.component;
    }


    const compName = comp['name'];
    if (compName === 'TabPagesComponent'
      || compName.indexOf('Auth') > -1
      || compName.indexOf('Login') > -1
      || compName.indexOf('Password') > -1) {
      if (compName.indexOf('Logout') > -1) {
        this.authService.isAuthenticated().subscribe(isAuth => {
          if (isAuth) {
            this.authService.logout(environment.securityStrategyName).subscribe(x => {
              window.location.reload();
            })
          }
        });
      }
      return;
    }
    // deactivate all tabs
    this.deactivateTabs();
    // check if the tab to be activated is already existing
    const params = this.getAllParams(this.router.routerState.snapshot.root, {});
    const id = params['id'];
    const name = this.getCompName(compName, params['model_type'], id);
    
    const currentTab = this.tabs.find(tab => tab.name === name);

    if (currentTab == null) {

      // if not, push it into the tab array
      this.tabs = this.tabs.filter(x => x.name.indexOf('-1') < 0);
      this.tabs.push({
        name: name,
        component: comp,
        key: compName,
        active: true,
        url: this.router.routerState.snapshot.url,
      });

    } else {
      currentTab.active = true;
    }

    this.cd.markForCheck();
  }

  deactivateTabs() {
    this.tabs.forEach(tab => (tab.active = false));
  }

  private getAllParams(snapshot: ActivatedRouteSnapshot, currentParams: { [key: string]: string }): { [key: string]: string } {
    if (snapshot) {

      if (snapshot.queryParams) {
        for (let param in snapshot.queryParams) {
          currentParams[param] = snapshot.queryParams[param];
        }

      }
      if (snapshot.params) {
        for (let param in snapshot.params) {
          currentParams[param] = snapshot.params[param];
        }
      }
      if (snapshot.children) {
        for (let i = 0; i < snapshot.children.length; i++) {
          this.getAllParams(snapshot.children[i], currentParams);
        }

      }
    }


    return currentParams;
  }

  private disposeTabByUrl(url: string) {
    const tab = this.tabs.filter(x => x.url === url);
    console.log('dispose ' + url + " " + tab);
    if (tab.length > 0) {
      this.disposeTab(tab[0]);
    }
  }

  navigateToTab(tab: Tab) {
    this.router.navigateByUrl(tab.url);
    // window.location.href = tab.url;
  }
}

export interface Tab {
  name: string;
  component: any;
  active: boolean;
  url: string;
  key: string;
}
