import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {NbAuthService} from '@nebular/auth';
import {environment} from '../../../environments/environment';

@Injectable()
export class LogoutInterceptor implements HttpInterceptor {

  constructor(private readonly authService: NbAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('logout')) {
      this.authService.logout(environment.securityStrategyName).subscribe(x => {
      });
      return of(new HttpResponse({status: 200}));
    }
    return next.handle(req);
  }
}
