import {Component} from '@angular/core';
import {ApiCrudService, BaseDynamicFormComponent, ITableConfig} from 'defdev-angular-nebular';
import {TransportViewModel} from '../../../generated-api/models/transport-view-model';
import {ModelTypes} from '../../../generated-api/models/model-types';

@Component({
  selector: 'ngx-venue-details',

  template: `
    <nb-card>
      <nb-card-header>
        Transport information
      </nb-card-header>
    </nb-card>
    <ng-container *ngIf="formModel">
      <ngx-dynamic-form #ngxForm [formModel]="formModel">

      </ngx-dynamic-form>
      <ngx-config-based-table-component [tableConfig]="contactsConfig" *ngIf="formModel.object.id > 0">
      </ngx-config-based-table-component>
      <ngx-config-based-table-component [tableConfig]="bookingsConfig" *ngIf="formModel.object.id > 0">
      </ngx-config-based-table-component>


      <nb-card>
        <nb-card-footer>
          <button nbButton status="basic" class="mr-2" (click)="onCancel()">Cancel</button>
          <button nbButton status="success" class="mr-2"
                  (click)="ngxForm.updateModel();save()"
                  [disabled]="ngxForm.form.invalid">Save
          </button>
          <button class="mr-2 " (click)="ngxForm.updateModel();save(false)" [disabled]="ngxForm.form.invalid"
                  [status]="'success'" hero
                  nbButton
          >Save and continue
          </button>
        </nb-card-footer>
      </nb-card>


    </ng-container>

  `,

})
export class TransportDetailsComponent extends BaseDynamicFormComponent<TransportViewModel> {


  // contatcs table
  contactsConfig: ITableConfig = {
    // it generates in bad way
    // TODO PAUL
    // bookigns are tourtiming i think but i is not created in backend
    // when you do it please remove false from template
    modelType: ModelTypes.TRANSPORT_CONTACT,
    title: 'Contacts',
    // how this elements are contected to owner
    // it can be value or function
    // you should filter bookings for current object
    // TODO PAUL referenced property is property to filter referenced by foreignkey objects
    // you want to load contacts of current trasport so you need to filter all contacts by transportid
    referencedProperties: [{type: 'integer', field: 'transportId', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    selectionMode: 'single',
    editModes: ['MODAL', 'INLINE'],
  };

  bookingsConfig: ITableConfig = {
    modelType: ModelTypes.TRANSPORT_BOOKING,
    title: 'Bookings',
    referencedProperties: [{type: 'integer', field: 'transportId', value: () => this.formModel.object.id}],
    showBulkUpdate: false,
    selectionMode: 'single',
    addButtonEnabled: false,
    deleteButtonEnabled: false,
    editModes: ['PAGE', 'INLINE'],
  };


  protected getService(): ApiCrudService<TransportViewModel> {
    return this.crudMapperApiService.getCrudServiceForModel(ModelTypes.TRANSPORT);
  }

}
