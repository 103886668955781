import {Component} from '@angular/core';
import {ApiClientsService} from '../../../generated-api/services';
import {ITableConfig} from 'defdev-angular-nebular';
import {ModelTypes} from '../../../generated-api/models';

@Component({
  selector: 'ngx-tour-clients',
  template: '<ngx-config-based-table-component [tableConfig]="tableConfig"></ngx-config-based-table-component>',
})
export class ClientsComponent {

  tableConfig: ITableConfig = {
    modelType: ModelTypes.CLIENT,
    title: 'Clients',
  };

  constructor(
    readonly service: ApiClientsService) {
  }

}
