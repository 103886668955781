import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from '../pages.component';
import {BidDetailsComponent} from './bid-details/bid-details.component';
import {BidsListComponent} from './bids-list/bids-list.component';
import {BidsCalendarComponent} from './bids-calendar/bids-calendar.component';
import {OpsDocumentListComponent} from './ops-document-list/ops-document-list.component';
import {OpsDocumentDetailsComponent} from './ops-document-details/ops-document-details.component';
import {DETAIL_COMPONENT_ROUTE, LIST_COMPONENT_ROUTE} from '../../constants';
import {TabPagesComponent} from "../tab-pages.component";


const routes: Routes = [{
  path: 'operation',
  children: [
    {
      path: 'calendar',
      component: BidsCalendarComponent,
    },
    {
      path: DETAIL_COMPONENT_ROUTE,
      component: BidDetailsComponent,
    },
    {
      path: DETAIL_COMPONENT_ROUTE + '/:id',
      component: BidDetailsComponent,
    },
    {
      path: LIST_COMPONENT_ROUTE,
      component: BidsListComponent,
    },
    {
      path: 'ops-document/' + LIST_COMPONENT_ROUTE,
      component: OpsDocumentListComponent,
    },
    {
      path: 'ops-document/' + DETAIL_COMPONENT_ROUTE,
      component: OpsDocumentDetailsComponent,
    },
    {
      path: 'ops-document/' + DETAIL_COMPONENT_ROUTE + '/:id',
      component: OpsDocumentDetailsComponent,
    },

    {
      path: '',
      redirectTo: 'operation',
      pathMatch: 'full',
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxBidRoutingModule {
}
