import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountSettingsComponent} from './account-settings/account-settings.component';
import {UsersSettingsComponent} from './users/users-settings.component';
import {NgxSettingsRoutingModule} from './settings-routing.module';
import {FormsModule} from '@angular/forms';
import {ClientsComponent} from './clients/clients.component';
import {LanguagesComponent} from './languages/languages.component';
import {OfficesComponent} from './offices/offices.component';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {DefaultTourDocumentsComponent} from './default-tour-documents/default-tour-documents.component';
import {ShipsComponent} from './ships/ships.component';
import {ImageLibraryComponent} from './image-library/image-library.component';
import {PortDetailsComponent} from './port-details/port-details.component';
import {ServicesComponent} from './services/services.component';
import {RegionsComponent} from './regions/regions.component';
import {PortsComponent} from './ports/ports.component';
import {DefdevAngularNebularModule, NebularModule} from 'defdev-angular-nebular';
import {VenuesComponent} from './venues/venues.component';
import {PortAgenciesComponent} from './port-agencies/port-agencies.component';
import {PortAgencyDetailsComponent} from './port-agency-details/port-agency-details.component';
import {ShipDetailsComponent} from './ship-details/ship-details.component';
import {UserDetailsComponent} from './user-details/user-details.component';
import {VenueDetailsComponent} from './venue-details/venue-details.component';
import {TransportComponent} from './transport/transport.component';
import {TransportDetailsComponent} from './transport-details/transport-details.component';
import {PortCoordinatorsComponent} from './users/port-coordinators.component';
import {GuidesComponent} from './users/guides.component';
import {BaseUsersComponent} from './users/base-users.component';
import {GuideDetailsComponent} from './guide-details/guide-details.component';
import {PaymentMethodsComponent} from './payment-methods/payment-methods.component';
import {TransportServicesComponent} from './transport-services/transport-services.component';
import {VenueGroupsComponent} from './venue-groups/venue-groups.component';
import {VenueDescriptionsComponent} from './venue-descriptions/venue-descriptions.component';
import {VenueCategoriesComponent} from './venue-categories/venue-categories.component';
import {ReportsComponent} from './reports/reports.component';

@NgModule({
  declarations: [
    BaseUsersComponent,
    AccountSettingsComponent,
    UsersSettingsComponent,
    ClientsComponent,
    LanguagesComponent,
    OfficesComponent,
    ClientDetailsComponent,
    DefaultTourDocumentsComponent,
    ShipsComponent,
    ImageLibraryComponent,
    PortDetailsComponent,
    ServicesComponent,
    RegionsComponent,
    PortsComponent,
    VenuesComponent,
    PortAgenciesComponent,
    PortAgencyDetailsComponent,
    ShipDetailsComponent,
    UserDetailsComponent,
    VenueDetailsComponent,
    TransportComponent,
    TransportDetailsComponent,
    PortCoordinatorsComponent,
    GuidesComponent,
    GuideDetailsComponent,
    PaymentMethodsComponent,
    TransportServicesComponent,
    VenueGroupsComponent,
    VenueDescriptionsComponent,
    VenueCategoriesComponent,
    ReportsComponent,
  ],
  imports: [
    NgxSettingsRoutingModule,
    CommonModule,
    FormsModule,
    NebularModule,
    DefdevAngularNebularModule,
  ],
})
export class SettingsModule {
}
