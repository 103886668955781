/* tslint:disable */
type TransportVenueStatusTypes =
  'Active' |
  'Preferred' |
  'DNU' |
  'NotOperating';
module TransportVenueStatusTypes {
  export const ACTIVE: TransportVenueStatusTypes = 'Active';
  export const PREFERRED: TransportVenueStatusTypes = 'Preferred';
  export const DNU: TransportVenueStatusTypes = 'DNU';
  export const NOT_OPERATING: TransportVenueStatusTypes = 'NotOperating';
  export function values(): TransportVenueStatusTypes[] {
    return [
      ACTIVE,
      PREFERRED,
      DNU,
      NOT_OPERATING
    ];
  }
}

export { TransportVenueStatusTypes }