/* tslint:disable */
type ModelTypes =
  'USER' |
  'GUIDE' |
  'TOUR' |
  'CLIENT' |
  'LANGUAGE' |
  'OFFICE' |
  'TOUR_TIMING' |
  'TOUR_LOCATION' |
  'PORT' |
  'DOCUMENT' |
  'CONFIG' |
  'NOTE' |
  'BID' |
  'SERVICE' |
  'REGION' |
  'SHIP' |
  'REPORT' |
  'VENUE' |
  'OPS_DOCUMENT' |
  'OPS_DOCUMENT_ACTION' |
  'TOUR_TIMING_ITINERY' |
  'PORT_AGENT' |
  'PORT_AGENCY' |
  'SHIP_ITINERY' |
  'BID_CANCELLATION' |
  'TRANSPORT' |
  'TRANSPORT_CONTACT' |
  'TRANSPORT_BOOKING' |
  'GUIDE_BOOKING' |
  'BOOKING' |
  'DETAILED_BOOKING_INFO' |
  'PAYMENT_METHOD' |
  'TRANSPORT_SERVICE' |
  'VENUE_CATEGORY' |
  'VENUE_DESCRIPTION' |
  'VENUE_GROUP' |
  'VENUE_BOOKING' |
  'VENUE_CONTACT';
module ModelTypes {
  export const USER: ModelTypes = 'USER';
  export const GUIDE: ModelTypes = 'GUIDE';
  export const TOUR: ModelTypes = 'TOUR';
  export const CLIENT: ModelTypes = 'CLIENT';
  export const LANGUAGE: ModelTypes = 'LANGUAGE';
  export const OFFICE: ModelTypes = 'OFFICE';
  export const TOUR_TIMING: ModelTypes = 'TOUR_TIMING';
  export const TOUR_LOCATION: ModelTypes = 'TOUR_LOCATION';
  export const PORT: ModelTypes = 'PORT';
  export const DOCUMENT: ModelTypes = 'DOCUMENT';
  export const CONFIG: ModelTypes = 'CONFIG';
  export const NOTE: ModelTypes = 'NOTE';
  export const BID: ModelTypes = 'BID';
  export const SERVICE: ModelTypes = 'SERVICE';
  export const REGION: ModelTypes = 'REGION';
  export const SHIP: ModelTypes = 'SHIP';
  export const REPORT: ModelTypes = 'REPORT';
  export const VENUE: ModelTypes = 'VENUE';
  export const OPS_DOCUMENT: ModelTypes = 'OPS_DOCUMENT';
  export const OPS_DOCUMENT_ACTION: ModelTypes = 'OPS_DOCUMENT_ACTION';
  export const TOUR_TIMING_ITINERY: ModelTypes = 'TOUR_TIMING_ITINERY';
  export const PORT_AGENT: ModelTypes = 'PORT_AGENT';
  export const PORT_AGENCY: ModelTypes = 'PORT_AGENCY';
  export const SHIP_ITINERY: ModelTypes = 'SHIP_ITINERY';
  export const BID_CANCELLATION: ModelTypes = 'BID_CANCELLATION';
  export const TRANSPORT: ModelTypes = 'TRANSPORT';
  export const TRANSPORT_CONTACT: ModelTypes = 'TRANSPORT_CONTACT';
  export const TRANSPORT_BOOKING: ModelTypes = 'TRANSPORT_BOOKING';
  export const GUIDE_BOOKING: ModelTypes = 'GUIDE_BOOKING';
  export const BOOKING: ModelTypes = 'BOOKING';
  export const DETAILED_BOOKING_INFO: ModelTypes = 'DETAILED_BOOKING_INFO';
  export const PAYMENT_METHOD: ModelTypes = 'PAYMENT_METHOD';
  export const TRANSPORT_SERVICE: ModelTypes = 'TRANSPORT_SERVICE';
  export const VENUE_CATEGORY: ModelTypes = 'VENUE_CATEGORY';
  export const VENUE_DESCRIPTION: ModelTypes = 'VENUE_DESCRIPTION';
  export const VENUE_GROUP: ModelTypes = 'VENUE_GROUP';
  export const VENUE_BOOKING: ModelTypes = 'VENUE_BOOKING';
  export const VENUE_CONTACT: ModelTypes = 'VENUE_CONTACT';
  export function values(): ModelTypes[] {
    return [
      USER,
      GUIDE,
      TOUR,
      CLIENT,
      LANGUAGE,
      OFFICE,
      TOUR_TIMING,
      TOUR_LOCATION,
      PORT,
      DOCUMENT,
      CONFIG,
      NOTE,
      BID,
      SERVICE,
      REGION,
      SHIP,
      REPORT,
      VENUE,
      OPS_DOCUMENT,
      OPS_DOCUMENT_ACTION,
      TOUR_TIMING_ITINERY,
      PORT_AGENT,
      PORT_AGENCY,
      SHIP_ITINERY,
      BID_CANCELLATION,
      TRANSPORT,
      TRANSPORT_CONTACT,
      TRANSPORT_BOOKING,
      GUIDE_BOOKING,
      BOOKING,
      DETAILED_BOOKING_INFO,
      PAYMENT_METHOD,
      TRANSPORT_SERVICE,
      VENUE_CATEGORY,
      VENUE_DESCRIPTION,
      VENUE_GROUP,
      VENUE_BOOKING,
      VENUE_CONTACT
    ];
  }
}

export { ModelTypes }